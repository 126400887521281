import { parentSlugsByGroup } from '../../business/categoryData';
import PriceDisplay from './PriceDisplay';
import TrafficLight from './TrafficLight';

import type { PriceListProduct } from 'pennyuk-business/src';

type ProductNarrativeProps = {
	item: PriceListProduct;
	priceDiscounted?: number;
	quantity: number;
	showSubTotal?: boolean;
	displayUnitPriceDiscounted?: boolean;
	subTotal?: number;
	subTotalWithPromo?: number;
}

export default function ProductNarrative({item, priceDiscounted, quantity, subTotal, showSubTotal, displayUnitPriceDiscounted}: ProductNarrativeProps) {

	return(
		<div className="product-narrative">
			<div className="item-name"><b>{item.name}</b></div>
			<div className="code">
				<span className="product-code">{item.product_code}</span>
				<br />
				<small title={"Group "+item.item_group} className={"cat cat-colour-" + parentSlugsByGroup[item.item_group]}>
					{item.item_group}
				</small>
			</div>
			<PriceDisplay
				price={item.price}
				priceDiscounted={priceDiscounted}
				showSubTotal={!!showSubTotal}
				subTotal={subTotal}
				displayUnitPriceDiscounted={displayUnitPriceDiscounted}
			/>
			<ProductStock stock={item.stock} quantity={quantity} />
		</div>
	);
}

export function ProductStock({ stock, quantity }: { stock: number, quantity: number }) {
	if(typeof stock !== 'number') {
		return <div className="stock" />;
	}

	return (
		<div className='stock'>
			<span className='small-label'>stock<br />status</span>
			<TrafficLight colour={quantity > stock ? 'AMBER' : 'GREEN'} />
		</div>
	);
}
