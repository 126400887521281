import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import productsReducer from 'redux/productsSlice';
import searchReducer from 'redux/searchSlice';
import { basketReducer, basketPreloadedState, basketToLocalStorage } from 'redux/basketSlice';
import userAccountReducer, { userAccountPreloadedState } from 'redux/userSlice';
import pwaReducer, { pwaPreloadedState } from 'redux/pwaSlice';

export const store = configureStore({
	reducer: {
		products: productsReducer,
		search: searchReducer,
		basket: basketReducer,
		user: userAccountReducer,
		pwa: pwaReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(basketToLocalStorage),
	preloadedState: {
		basket: basketPreloadedState(),
		user: userAccountPreloadedState(),
		pwa: pwaPreloadedState()
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
