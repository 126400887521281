import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeText } from '../../redux/searchSlice';
import { useRouterPriceListSearch } from '../../redux/customRouterHooks';
import { categoryData } from '../../business/categoryData';

export default function SearchBar() {
	const dispatch = useDispatch();
	const [searchInput, setSearchInput] = useState('');
	const activeCategory = useSelector(state => state.products.category);

	useRouterPriceListSearch(setSearchInput);

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			dispatch(changeText(searchInput.trim().toLowerCase()));
		}, 300);
		return () => clearTimeout(delayDebounce)
	}, [searchInput]);  //eslint-disable-line react-hooks/exhaustive-deps

	return(<div id='search-bar'><input
		value={searchInput}
		type="search"
		aria-label="search products"
		placeholder={activeCategory === '/' ? 'Search' :  `Search in ${categoryData[activeCategory].name}`}
		autoFocus
		onChange={(e) => {setSearchInput(e.target.value)}}
	/></div>);
}
