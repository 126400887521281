import { useEffect } from 'react';

import { setUser, toggleLoggedIn } from 'redux/userSlice';
import { checkForLoggedInUser, getUserAttributes } from '../business/rules';
import { useAppDispatch } from 'redux/customHooks';

const stageFromDeployEnv = process.env.REACT_APP_DEPLOY_STAGE;

export function useCheckForLoggedInUser() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		checkForLoggedInUser()
			.then(user => {
				if(user) {
					return getUserAttributes(user);
				}

				throw new Error('No current user');
			})
			.then(attributes => {
				const email = attributes.find(a => a.Name === 'email');
				const givenName = attributes.find(a => a.Name === 'given_name');
				const familyName = attributes.find(a => a.Name === 'family_name')

				dispatch(setUser({
					email: email?.Value ?? '',
					name: `${givenName?.Value ?? ''} ${familyName?.Value ?? ''}`
				}))
			})
			.catch(e => {
				if(stageFromDeployEnv && stageFromDeployEnv !== "prod" && e === "The user is not authenticated"){
					console.info('No current user.');
				}

				dispatch(toggleLoggedIn(false));
			})
	}, []); //eslint-disable-line react-hooks/exhaustive-deps
}