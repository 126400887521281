import { BrowserRouter,	Routes,	Route } from "react-router-dom";

import NavMainMenu from './components/NavMainMenu';
import { Footer } from 'components/Footer';
import PriceListHome from './components/PriceListHome';
import OrderBasket from './components/order/OrderBasket';
import TermsOfSale from './components/TermsOfSale';
import SecurityAndPrivacy from './components/SecurityAndPrivacy';
import UserAccount from './components/user-account/UserAccount';
import ScrollToTop from './components/controls/ScrollToTop';
import Contact from './components/Contact';
import Help from './components/Help';
import { useSendFetchProducts, useSendFetchDiscounts, useWebAppStatus } from './redux/customHooks';
import { useCheckForLoggedInUser } from "hooks/useCheckForLoggedInUser";

export default function App() {

	useSendFetchProducts();
	useCheckForLoggedInUser();
	useSendFetchDiscounts();
	useWebAppStatus();

	return(
		<BrowserRouter>
			<NavMainMenu />
			<Routes>
				<Route path="/code/:code" element={<PriceListHome />} />
				<Route path="/slug/:slug" element={<PriceListHome />} />
				<Route path="/product/:search" element={<PriceListHome />} />
				<Route path="/group/:itemgroup" element={<PriceListHome />} />
				<Route path="/product-category/groups/:itemgroup" element={<PriceListHome />} />
				<Route path="/product-category/:catparent/:catslug" element={<PriceListHome />} />
				<Route path="/product-category/:slugorgroup" element={<PriceListHome />} />
				<Route path="/basket" element={<OrderBasket />} />
				{/* TODO - get tokens from hosted ui implicit
				<Route path="/user-account/auth/#id_token=:implicitid&access_token=:implicitaccess"></Route> */}
				<Route path="/user-account/*" element={<UserAccount />} />
				<Route path="/terms-conditions" element={<TermsOfSale />} />
				<Route path="/privacy-policy" element={<SecurityAndPrivacy />} />
				<Route path="/help" element={<Help />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/:parent/:child" element={<PriceListHome />} />
				<Route path="/" element={<PriceListHome />} />
			</Routes>
			<ScrollToTop />
			<Footer />
		</BrowserRouter>
	);
}
