import { useSelector, useDispatch } from 'react-redux';
import { changeSort } from '../../redux/productsSlice';

const ALLOWED = ["default", "name","namedesc","price","pricedesc"];

export default function SortControl() {
	const dispatch = useDispatch();
	const sort = useSelector(state => state.products.sort);

	return <select className="sort-control lightblue-rounded-button" name="orderby" aria-label="Price List items order"
		value={sort}
		onChange={(event) => {
			if(ALLOWED.includes(event.target.value)){
				dispatch(changeSort(event.target.value));
			}
		}}>
		<option value="default">Sort by default</option>
		<option value="name">Sort by name: a-z</option>
		<option value="namedesc">Sort by name: z-a</option>
		{/*<option value="num">Sort by numeric: low to high</option>
		<option value="numdesc">Sort by numeric: high to low</option>*/}
		<option value="price">Sort by price: low to high</option>
		<option value="pricedesc">Sort by price: high to low</option>
	</select>
}
