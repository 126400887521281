import type { AsyncStatus } from 'business/types';

const stageFromDeployEnv = process.env.REACT_APP_DEPLOY_STAGE;

export function Loading({ status, error }: { status: AsyncStatus; error?: string; }) {
	if(status === 'failed'){
		return <p>
			<samp data-error={!!error ? error : ""}><b>Loading has failed.</b></samp><br />
			{!!error && stageFromDeployEnv !== "prod" ? <samp>Dev only message: {error}</samp> : ''}
		</p>
	}else if(status === 'loading'){
		return <LoadingSpinner />
	}else{
		return <p></p>
	}
}

export function LoadingSpinner() {
	return(<div className="loading-spinner-ripple">
		<div className="loading-spinner-ripple-inner">
			<div></div>
			<div></div>
		</div>
	</div>);
}
