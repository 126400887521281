import React from 'react';

export default function ExpandInfo({infoExpanded, setInfoExpanded}) {
	return (<button
		type="button"
		name="expand-product-information"
		className="expand-product-information lightblue-rounded-button"
		title="click to expand product information"
		onClick={() => {
			if(infoExpanded){
				setInfoExpanded(false);
			}else{
				setInfoExpanded(true);
			}
		}}
	>
		<span>i</span>
	</button>)
}
