import { Fragment, useEffect, useState } from 'react';
import AddToOrderControl from '../controls/AddToOrderControl';
import ProductNarrative from './ProductNarrative';
import ProductItemExpanded from './ProductItemExpanded';
import ExpandInfo from '../controls/ExpandInfo';
import { PriceListProduct } from 'pennyuk-business/src';
import { useAppSelector } from 'redux/customHooks';

export default function ProductItem({ item }: { item: PriceListProduct & { priceDiscounted: number } }) {
	const allProductInfoExpanded = useAppSelector(state => state.user.productInfoExpanded);
	const [infoExpanded, setInfoExpanded] =	useState(allProductInfoExpanded);
	const [quantity, setQuantity] = useState(1);

	useEffect(() => {setInfoExpanded(allProductInfoExpanded);},[allProductInfoExpanded]);

	return(<div
		className="price-list-row"
		data-guid={item.id}
		data-productcode={item.product_code}
		data-group={item.item_group}
		data-parent={(!!item.parent ? item.parent : "none")}
		data-tags={!!item.meta?.tags ? item.meta.tags.join() : ""}
	>
		<div className="primary">
			<div className="expand-product-details">
				<ExpandInfo infoExpanded={infoExpanded} setInfoExpanded={setInfoExpanded} />
			</div>
			<ProductNarrative
				item={item}
				showSubTotal={false}
				quantity={quantity}
				priceDiscounted={item.priceDiscounted}
				displayUnitPriceDiscounted
			/>
			<AddToOrderControl item={item} quantity={quantity} setQuantity={setQuantity} />
		</div>
		{infoExpanded ? <ProductItemExpanded item={item} /> : <Fragment />}
	</div>);
}


