import React from 'react';
import Header from './Header';

const currentTermsEffectiveDate = new Date(process.env.REACT_APP_TERMS_DATE);
const companyAltName = process.env.REACT_APP_COMPANY_ALT_NAME;

export default function TermsOfSale() {
	return (<div id='terms-conditions' className={"nav-page"}>
		<Header title={`${companyAltName} – Standard Conditions of Sale`} />
		<main className="">
			<p><strong>YOUR ATTENTION IS PARTICULARLY DRAWN TO THE LIMITATIONS ON OUR LIABILITY SET OUT IN CLAUSE 5.3, CLAUSE 6 AND CLAUSE 12 BELOW OF THESE TERMS AND CONDITIONS</strong></p>
			<h3>Terms &amp; Conditions, from {currentTermsEffectiveDate.toLocaleDateString()}</h3>
			<ol className="sections">
				<li data-section="1"><strong>Definitions</strong>
					<p>In these conditions, the following definitions shall apply:</p>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Applicable Laws'</b> mean means all applicable laws, legislation, statutory instruments, regulations and governmental guidance having binding force whether local or national;</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Bribery Laws'</b> means the Bribery Act 2010 and all Applicable Laws in connection with bribery or anti-corruption;</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Buyer'</b> means the person who buys or agrees to buy the Goods from {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Conditions'</b> means the terms and conditions of sale set out in this contract and any special terms and conditions agreed in writing by {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Contract'</b> means the agreement between {companyAltName} and the Buyer for the sale and purchase of the Goods incorporating these Conditions and the Order;</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Delivery Date'</b> means the date specified by {companyAltName} when the Goods are to be delivered.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Goods'</b> means the individual products which the Buyer agrees to buy from {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'Price'</b> means the price for the Goods excluding carriage, packing, insurance and VAT.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content"><b>'{companyAltName}'</b> means Penny Irrigation UK Limited, a private limited company incorporated in England and Wales with company number 03022043 and whose registered office is Oakfield Plumley Moor Road, Plumley, Knutsford, England, WA16 9RS.</span></span></li>
					</ol>
				</li>
				<li data-section="2" data-c={true}><strong>Conditions Applicable</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">These Conditions shall apply to all contracts for the sale of Goods by {companyAltName} to the Buyer to the exclusion of all other terms and conditions including any terms and conditions which the Buyer may purport to apply under any purchase order, confirmation of order or similar document.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Subject to clause 2.3, all orders for Goods shall be placed in writing (whether by order form, email or other electronic means) and shall be deemed to be an offer by the Buyer to purchase Goods pursuant to these Conditions.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Whilst it is the preference that Orders are placed in writing, {companyAltName} may, at its discretion, accept Orders placed verbally.</span></span></li>
						<li>
							<span className="list-row">
								<span className="ndotn-point"></span>
								<span className="ndotn-content">{companyAltName} may accept or reject an Order at its discretion. An Order shall not be accepted, and no binding obligation to supply any Goods shall arise, until the earlier of:</span>
							</span>
								<ol className="letter-bracket">
									<li>{companyAltName}'s written acceptance of the Order (including by email or other electronic means); or</li>
									<li>{companyAltName} dispatching the Goods.</li>
								</ol>
						</li>

						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Acceptance of delivery of the Goods shall be deemed conclusive evidence of the Buyer's acceptance of these Conditions.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any variation to these Conditions (including any special terms and conditions agreed) shall be inapplicable unless agreed in writing by {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may issue quotations to the Buyer from time to time. Quotations are invitations to treat only. They are not an offer to supply the Goods and are incapable of being accepted by the Buyer. All quotations are valid for a period of 30 days from the date of issue.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any samples, drawings, descriptive matter, or advertising produced by {companyAltName} and any descriptions or illustrations contained in {companyAltName}'s catalogues, website or brochures in connection with the Goods are produced for the sole purpose of giving an approximate idea of the Goods described in them. They shall not form part of the Contract or have any contractual force.</span></span></li>
					</ol>
				</li>
				<li data-section="3" data-c={true}><strong>Price</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Price of the Goods shall be {companyAltName}'s quoted price which shall be binding on  {companyAltName} provided that the Buyer places an Order for the Goods within 30 days of the price quotation.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may by giving notice to the Buyer at any time up to 7 days before delivery increase the Price of the Goods to reflect any increase in the cost to {companyAltName} which is due to any increased cost of labour, materials and other manufacturing costs of the Goods provided that the Buyer may cancel this contract within 3 days of any such notice from {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Price is exclusive of:</span></span>
							<ol className="letter-bracket">
								<li>VAT which shall be due at the rate ruling of the date of VAT invoice.</li>
								<li>packaging, delivery, insurance, shipping carriage, and all other related charges which shall be charged in addition at {companyAltName}'s standard rates.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li data-section="4" data-c={true}><strong>Payment</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} shall invoice the Buyer for the Goods, partially or in full, at any time following acceptance of the Order.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Payment of the price and VAT shall be due by the last business day of the month following the month in which the invoice is issued unless otherwise agreed in writing and irrespective of delivery. {companyAltName} shall not be bound to deliver the Goods until the Buyer has paid all outstanding monies owed to {companyAltName}. Time for payment shall be of the essence.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If the Buyer fails to pay the Price for the Goods on the due date then without Prejudice to any of {companyAltName}'s other rights, {companyAltName} may;</span></span>
							<ol className="letter-bracket">
								<li>suspend or cancel deliveries of any Goods due to the Buyer; and/or</li>
								<li>appropriate any payment made by the Buyer to such of the Goods (or Goods supplied under any other contract or Order with the buyer) as {companyAltName} may in its sole discretion think fit.</li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Interest on overdue invoices shall accrue from the date when payment becomes due from day to day until the date of payment at a rate of 8% above Barclays Bank Plc's base rate from time to time in force and shall accrue at such a rate after as well as before any judgement.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall not be entitled to assert any set-off or counterclaim against {companyAltName} in order to justify withholding payment of any amount due to {companyAltName} in whole or in part.</span></span></li>
					</ol>
				</li>
				<li data-section="5" data-c={true}><strong>The Goods</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Goods shall be manufactured and supplied in accordance with the description contained in {companyAltName}'s specification for the Goods and manufactured in accordance with all applicable or international standards which relate specifically to the Goods.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may from time to time make changes in the specification of the Goods which are required to comply with any applicable safety or statutory requirements. In addition, {companyAltName} operates a policy of continuous product improvement and reserves the right to change the design, construction and/or specification of the Goods at any time if such change will in the sole opinion of {companyAltName} at the time that such change is made, improve or enhance the design, quality or performance of such Goods.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Professional or technical advice or assistance on the installation of the Goods is not included by {companyAltName} in the Price for the Goods. Any information by {companyAltName} (including but not limited to any information, advice or guidance given orally at site visits by representatives of {companyAltName}) in this respect is provided for general information purposes only and cannot be relied upon. The Buyer and/or any third party to whom the Goods are supplied are advised to always seek professional installation advice from a qualified irrigation expert. {companyAltName} gives no warranty as to the accuracy of such information, advice or guidance and does not accept any liability for error or omission in respect of the same.</span></span></li>
					</ol>
				</li>
				<li data-section="6" data-c={true}><strong>System Design</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Goods are supplied as individual products. Whilst {companyAltName} may provide general technical advice in relation to the Goods, {companyAltName} gives no warranty that the Goods  or any proposed systems incorporating such Goods are suitable for the final operating environment proposed by the Buyer.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer must satisfy itself, or take appropriate professional advice, to ensure that the end system incorporating the Goods performs to the Buyer's requirements. The Buyer acknowledges that it has greater knowledge of its own requirements and shall rely on its own skill and judgement in evaluating the suitability of the Goods for the proposed end purpose.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any drawings or designs in respect of systems provided by {companyAltName} are for the sole purpose of giving approximate descriptions of the goods in their operating environment and cannot be relied upon by the Buyer. {companyAltName} offers no guarantee that such designs are error free or suitable for the Buyer's requirements.</span></span></li>
					</ol>
				</li>
				<li data-section="7" data-c={true}><strong>Warranties</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} warrants that the Goods shall at the time of delivery:</span></span>
								<ol className="subparagraphs">
									<li><span className="count subparagraph"></span><span className="content subparagraph">correspond in all material respects with the description contained in {companyAltName}'s specification for the Goods; and</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">be free from material defects in design, material and workmanship.</span></li>
								</ol>
						</li>
						<li>
							<span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">As the Buyer's sole and exclusive remedy, {companyAltName} shall, at its option, repair, replace, or refund the Price of any of the Goods that do not comply with clause 7.1, provided that the Buyer:</span></span>
								<ol className="subparagraphs">
									<li>
										<span className="count subparagraph"></span><span className="content subparagraph">serves a written notice on {companyAltName}:</span>
										<ol type="i">
											<li>during the period of 5 business days from the date of delivery in the case of defects discoverable by a physical inspection; or</li>
											<li>in the case of latent defects, within 10 business days from the date on which the Buyer became aware (or should reasonably have become aware) of the defect;</li>
										</ol>
									</li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">provides {companyAltName} with sufficient information as to the nature and extent of the defects and the uses to which the Goods had been put prior to the defect arising;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">gives {companyAltName} a reasonable opportunity to examine the defective Goods; and</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">returns the defective Goods to {companyAltName} at the Buyer's expense.</span></li>
								</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} shall not be liable for any failure of the Goods to comply with clause 7.1:</span></span>
								<ol className="subparagraphs">
									<li><span className="count subparagraph"></span><span className="content subparagraph">where such failure arises by reason of wear and tear, wilful damage, negligence, or could be expected to arise in the normal course of use of the Goods;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">to the extent caused by the Buyer's failure to comply with {companyAltName}'s instructions in relation to the Goods;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">to the extent caused by {companyAltName} following any specification, instruction or requirement of or given by the Buyer in relation to the Goods;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">where the Buyer modifies any Goods without {companyAltName}'s prior written consent or, having received such consent, not in accordance with {companyAltName}'s instructions; or</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">where the Buyer uses any of the Goods after notifying {companyAltName} that they do not comply with clause 7.1.</span></li>
								</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Except as set out in this clause 7:</span></span>
								<ol className="subparagraphs">
									<li><span className="count subparagraph"></span><span className="content subparagraph">{companyAltName} gives no warranties and makes no representations in relation to the Goods; and</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">shall have no liability for their failure to comply with the warranty in clause 7.1; and</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">all warranties and conditions (including the conditions implied by ss 13–15 of the Sale of Goods Act 1979), whether express or implied by statute, common law or otherwise are excluded to the extent permitted by law.</span></li>
								</ol>
						</li>
					</ol>
				</li>

				<li data-section="8" data-c={true}><strong>Delivery of the Goods</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Delivery of the Goods shall be made to the Buyer's address as notified to {companyAltName} in writing on the Delivery Date. The Goods may be delivered in advance of the Delivery Date upon the giving of reasonable notice to the Buyer. The Buyer shall make all arrangements to take delivery of the Goods whenever they are tendered for delivery.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may deliver the Goods by separate instalments. Any delay or defect in an  instalment shall not entitle the Buyer to cancel any other instalment.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The failure or refusal of the Buyer to take delivery of the Goods including any delivery by instalments on the due dates shall entitle {companyAltName}:</span></span>
							<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">without notice to suspend further deliveries of the Goods pending payment by the Buyer for any Goods already received by the Buyer; and/or</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">to treat this contract as repudiated by the Buyer.</span></li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall promptly obtain all necessary import licenses, clearances and other consents necessary for the purchase of the Goods. {companyAltName} shall promptly upon request supply all documents reasonably required by the Buyer for this purpose.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Time of delivery is not of the essence. {companyAltName} shall use its reasonable endeavours to meet delivery dates but such dates are indicative only.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} shall not be liable for any loss or damage whatever due to failure by {companyAltName} to deliver the Goods (or any of them) promptly or at all.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Notwithstanding that {companyAltName} may have delayed or failed to deliver the Goods ( or any of them) promptly the buyer shall be bound to accept delivery and to pay for the goods in full provided that delivery shall be tendered at any time within 3 months of the Delivery Date.</span></span></li>
					</ol>

				</li>
				<li data-section="9" data-c={true}><strong>Inspection of Goods</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer is under a duty to inspect the Goods on delivery to satisfy itself that the Goods are in compliance with the Contract. In particular, the Buyer shall on delivery, and before signing the delivery note, check that the number of items received is correct and that the boxes have not been damaged in transit.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">For the purposes of clause 9.1, Goods shall not conform to the Contract if there is any shortage in quantity specified in the Order or failure to comply with the warranty conditions set out in clause 7.1 of these Conditions.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall be entitled to reject and return any Goods if there is a shortage in quantity specified in the Order providing it notifies {companyAltName} of the same within 5 business days of delivery providing that it complies with {companyAltName}'s return and authorisation procedure.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">For the avoidance of doubt, the Buyer will be deemed to have accepted the Goods if it fails to notify {companyAltName} of the shortage within 5 business days of the date of delivery and reject the Goods. In such circumstances, {companyAltName} shall have no further liability in respect of the shortage.</span></span></li>
					</ol>
				</li>
				<li data-section="10" data-c={true}><strong>Acceptance of the Goods</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">No Goods delivered to the Buyer which are in accordance with these terms and conditions of contract will be accepted for return without the prior written approval of {companyAltName} and provided that the Buyer shall comply with {companyAltName}'s returns authorisation procedure and on any other terms which may be determined at the absolute discretion of {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If {companyAltName} agrees to accept any such Goods which are returned subject to clause 10.1 {companyAltName} reserves the right to levy a handling charge of 10% of the invoice price. Such Goods must be returned by the Buyer carriage-paid to {companyAltName} in their original packaging.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Goods returned without the prior written approval of {companyAltName} may at {companyAltName}'s absolute discretion be returned to the Buyer or stored at the Buyer's cost without prejudice to any rights or remedies {companyAltName} may have.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Notwithstanding the receipt by {companyAltName} of any notice of return of the Goods by the Buyer for any reason a clear signature on a carriers note shall be deemed to signify acceptance of the Goods by the Buyer.</span></span></li>
					</ol>
				</li>
				<li data-section="11" data-c={true}><strong>Retention of Title</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Goods shall be at the Buyer's risk as from delivery to the Buyer's address.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">In spite of delivery having been made title in the Goods shall not pass from {companyAltName} until:</span></span>
							<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">the Buyer shall have paid the Price plus VAT in full; and</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">no other sums whatever shall be due from the Buyer to {companyAltName}.</span></li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Until the title in the Goods passes to the Buyer in accordance with clause 10.2 the Buyer shall hold the Goods and each of them on a fiduciary basis as bailee for {companyAltName}. The Buyer shall store the Goods (at no cost to {companyAltName}) separately from all other goods in its possession and marked in such a way that they are clearly identified as {companyAltName}'s property.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Notwithstanding that the Goods (or any of them) remain the property of {companyAltName} the Buyer may sell or use the Goods in the ordinary course of the Buyer's business at full market valve for the account of {companyAltName}. Any such sale or dealing shall be a sale or use of {companyAltName}'s property by the Buyer on the Buyer's own behalf and the Buyer shall deal as principal when making such sale or dealings. Until property in the Goods passes from {companyAltName} the entire proceeds of sale or otherwise of the Goods shall be held in trust for {companyAltName} and shall not be mixed with other money or paid into any overdrawn bank account and shall be at all material times as {companyAltName}'s money.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} shall be entitled to recover the price (plus VAT) notwithstanding that property in any Goods has not passed from {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Until such time as property in the Goods passes from {companyAltName} the Buyer shall upon request deliver up such of the Goods as have not ceased to be in existence or resold, to {companyAltName}. If the Buyer fails to do so {companyAltName} may enter upon any premises owned occupied or controlled by the Buyer where the Goods are situated and repossess the Goods. On the making of such request the rights of the Buyer under clause 11.4 shall cease.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall not pledge or in any way charge by way of security for any indebtedness any of the Goods which are the property of {companyAltName}. Without prejudice to the other rights of {companyAltName}, if the Buyer does so all sums whatever owing by the Buyer to {companyAltName} shall forthwith become due and payable.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall insure and keep insured the Goods to the full Price against 'all risks' to the reasonable satisfaction of {companyAltName} until the date that title in the Goods passes from {companyAltName} and shall whenever requested by {companyAltName} produce a copy of the policy of insurance. Without prejudice to the other rights of {companyAltName}, if the Buyer fails to do so all sums whatever owing by the Buyer to {companyAltName} shall forthwith become due and payable.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall indemnify {companyAltName} from and against any losses, damages, liability, costs (including legal fees) and expenses which {companyAltName} may suffer or incur directly or indirectly from the Buyer's breach of any of its obligations under this Contract.</span></span></li>
					</ol>
				</li>
				<li data-section="12" data-c={true}><strong>Limitation on Liability</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">In the event of any breach of this Contract by {companyAltName} (regardless of whether such liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation) the remedies of the Buyer shall be limited to damages as set out in this clause 12.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Subject to clause 12.5, {companyAltName}'s total liability shall not exceed the Price paid by the Buyer for the Goods in question.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Subject to clause 12.5, {companyAltName} shall not be liable for consequential, indirect or special losses.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Subject to clause 12.5, {companyAltName} shall not be liable for any of the following (whether direct or indirect):</span></span>
							<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of profit;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of data;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of use;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of production or damage to crops;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of contract;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of opportunity;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">loss of savings, discount or rebate (whether actual or anticipated); or</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">harm to reputation or loss of goodwill.</span></li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Notwithstanding any other provision of these Conditions, the liability of the parties shall not be limited in any way in respect of the following:</span></span>
							<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">death or personal injury caused by negligence;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">fraud or fraudulent misrepresentation;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">any other losses which cannot be excluded or limited by Applicable Law;</span></li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">In the event that the Buyer declines to accept the Goods in breach of this Contract the Buyer shall pay to {companyAltName} as and by way of agreed liquidated damages an amount equal to the price of the Goods less the net proceeds received by {companyAltName} on reselling the Goods after deducting the costs and expenses of resale.</span></span></li>
					</ol>

				</li>
				<li data-section="13" data-c={true}><strong>Intellectual Property</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any intellectual property rights of the Goods including but not limited to patents, registered or unregistered designs, trademarks and copyright shall be the property of {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Where any designs or specifications have been supplied by the Buyer for manufacture by {companyAltName} or to the order of the Buyer then the Buyer warrants that the use of those designs or specifications for the manufacture, processing, assembly or supply of the Goods shall not infringe the intellectual property rights of any third party and shall indemnify {companyAltName} against all losses, damages, liability, costs and expenses (including reasonable legal fees) incurred by {companyAltName} in connection with any claim arising from the use of such designs or specifications by the {companyAltName}.</span></span></li>
					</ol>

				</li>
				<li data-section="14" data-c={true}><strong>Force Majeure</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Neither party will be liable for any delay in performing or failure to perform any of its obligations under this contract (other than a failure to pay the Price when due) caused by events beyond its reasonable control (<b>'Force Majeure Event'</b>).</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Party claiming the Force Majeure Event will promptly notify the other in writing of the reasons for the delay or stoppage (and the likely duration) and will take all reasonable steps to overcome the delay or stoppage.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If the party claiming the Force Majeure Event has compiled with clause 14.2 its performance under this contract will be suspended for the period that the Force Majeure Event continues and the party will have an extension of time for performance which is reasonable and in any event equal to the period of delay or stoppage. As regards such delay or stoppage:</span></span>
						<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">any costs arising from the delay or stoppage will be borne by the party incurring those costs;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">either party may, if the delay or stoppage continues for more than 30 continuous days, terminate this contract with immediate effect on giving written notice to the other and neither party will be liable to the other for such termination; and</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">the party claiming the Force Majeure Event will take all necessary steps to bring that event to a close or to find a solution by which this contract may be performed despite the Force Majeure Event.</span></li>
							</ol>
						</li>
					</ol>					
				</li>
				<li data-section="15" data-c={true}><strong>Set Off</strong>
					<ul>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Notwithstanding anything contained or implied in this contract {companyAltName} may set off against any money which would otherwise be owing by {companyAltName} to the Buyer under or pursuant to the Contract or until the Buyer has paid, satisfied or discharged all monies, debts or other liabilities due or owing to {companyAltName}. All payments made by the Buyer under the Contract it shall be made in full without any set-off or counter claim whatever.</span></span></li>
					</ul>
				</li>
				<li data-section="16" data-c={true}><strong>Termination</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may terminate this Contract (and/or exercise its rights under Clause 11) at any time by giving notice in writing to the Buyer if the Buyer:</span></span>
								<ol className="subparagraphs">
									<li><span className="count subparagraph"></span><span className="content subparagraph">commits a material breach of the Contract and such breach is not remediable;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">commits a material breach of the Contract which is not remedied within 10 days of receiving written notice of such breach;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">has failed to pay any amount due under the Contract on the due date and such amount remains unpaid 10 days after the date that {companyAltName} has given notification to the Buyer that the payment is overdue; {companyAltName} may terminate the Contract at any time by giving notice in writing to the Buyer if the Buyer:</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">stops carrying on all or a significant part of its business, or indicates  in any way that it intends to do so;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">is unable to pay its debts either within the meaning of section 123 of the Insolvency Act 1986 or if {companyAltName} reasonably believes that to be the case;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">becomes the subject of a company voluntary arrangement under the Insolvency Act 1986;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">becomes subject to a moratorium under Part A1 of the Insolvency Act 1986;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">becomes subject to a restructuring plan under Part 26A of the Companies Act 2006;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">becomes subject to a scheme of arrangement under Part 26 of the Companies Act 2006;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">has a receiver, manager, administrator or administrative receiver appointed over all or any part of its undertaking, assets or income;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">has a resolution passed for its winding up;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">has a petition presented to any court for its winding up or an application is made for an administration order, or any winding-up or administration order is made against it;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">is subject to any procedure for the taking control of its goods that is not withdrawn or discharged within 7 days of that procedure being commenced;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">has a freezing order made against it;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">is subject to any recovery or attempted recovery of items supplied to it by a supplier retaining title to those items;</span></li>
									<li><span className="count subparagraph"></span><span className="content subparagraph">is subject to any events or circumstances analogous to those in clauses 16.1.1 to 16.1.12 in any jurisdiction.</span></li>
								</ol>
							</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If the Buyer becomes aware that any event has occurred, or circumstances exist, which may entitle {companyAltName} to terminate the Contract under this clause 16, it shall immediately notify {companyAltName} in writing.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Termination or expiry of the Contract shall not affect any accrued rights and liabilities of {companyAltName} at any time up to the date of termination.</span></span></li>
					</ol>
				</li>
				<li data-section="17" data-c={true}><strong>Anti-bribery</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">For the purposes of this clause 17 the expressions 'adequate procedures' and 'associated with' shall be construed in accordance with the Bribery Act 2010 and legislation or guidance published under it.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Each party shall comply with applicable Bribery Laws including ensuring that it has in place adequate procedures to prevent bribery and [use all reasonable endeavours to ensure that:</span></span>
							<ol className="subparagraphs">
								<li><span className="count subparagraph"></span><span className="content subparagraph">all of its personnel;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">all others associated with it; and</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">all of its subcontractors;</span></li>
								<li><span className="count subparagraph"></span><span className="content subparagraph">involved in performing the contract so comply.</span></li>
							</ol>
						</li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Without limitation to clause 17.2, neither party shall make or receive any bribe (which term shall be construed in accordance with the Bribery Act 2010) or other improper payment or advantage or allow any such bribe or improper payment or advantage to be made or received on its behalf, either in the United Kingdom or elsewhere and shall implement and maintain adequate procedures to ensure that such bribes or improper payments or advantages are not made or received directly or indirectly on its behalf.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall immediately notify {companyAltName} as soon as it becomes aware of a breach by the Buyer of any of the requirements in this clause 17.</span></span></li>
					</ol>
				</li>
				<li data-section="18" data-c={true}><strong>Assignment</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} may assign transfer or sub-contract its rights or obligations of \ this contract to any third party.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The Buyer shall not assign transfer or sub-contract its rights or obligations of this contract without the prior written consent of {companyAltName}, such consent not to be unreasonably withheld.</span></span></li>
					</ol>
				</li>
				<li data-section="19" data-c={true}><strong>Waiver</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Neither {companyAltName}'s failure to exercise any power given to {companyAltName} under this contract or to insist upon strict compliance by the Buyer with any obligation under it, nor any custom or practice of {companyAltName} or the Buyer shall constitute any wavier of any of {companyAltName}'s rights under this contract.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Waiver by {companyAltName} of any particular default by the Buyer must be in writing and shall not affect or impair {companyAltName}'s rights in respect of any subsequent default of any kind by the Buyer, nor shall any delay by or omission of {companyAltName} to exercise any rights arising from any of the Buyer's defaults affect or impair {companyAltName}'s rights in respect of the said default or any default of any kind.</span></span></li>
					</ol>
				</li>
				<li data-section="20" data-c={true}><strong>Notices</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any notice required to be given for the purposes of this contract must be given by sending it by pre-paid first class post, fax or email, or by delivery by hand at the relevant address shown in this contract or such other address as has been notified in accordance with this clause by the party concerned as being its address for the purposes of this clause.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any notice sent by post shall be deemed to have been served 2 days after posting. In proving service it shall be sufficient to prove that a notice was properly addressed and stamped and put into the post.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any notice sent by fax shall be deemed to have been served on the next business day following the date of despatch of it.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any notice sent by email shall be deemed to have been served on the next business day following the date of transmission.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Any notice delivered by hand shall be deemed to have been served when physically delivered at the relevant address.</span></span></li>
					</ol>
				</li>
				<li data-section="21" data-c={true}><strong>Entire agreement</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The parties agree that the Contract and any documents entered into pursuant to it constitutes the entire agreement between them and supersedes all previous agreements, understandings and arrangements between them, whether in writing or oral in respect of its subject matter.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Each party acknowledges that it has not entered into the Contract or any documents entered into pursuant to it in reliance on, and shall have no remedies in respect of, any representation or warranty that is not expressly set out in these Conditions. No party shall have any claim for innocent or negligent misrepresentation on the basis of any statement in these Conditions.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Nothing in these Conditions purports to limit or exclude any liability for fraud</span></span></li>
					</ol>
				</li>
				<li data-section="22"><strong>No partnership or agency</strong>
					<ul><li>The parties are independent persons and are not partners, principal and agent or employer and employee and the Contract does not establish any joint venture, trust, fiduciary or other relationship between them, other than the contractual relationship expressly provided for in it. None of the parties shall have, nor shall represent that they have, any authority to make any commitments on the other party's behalf.</li></ul>
					
				</li>
				<li data-section="23"><strong>Cumulative remedies</strong>
					<ul><li>The rights and remedies provided in the Contract for {companyAltName} only are cumulative and
						not exclusive of any rights and remedies provided by law.</li></ul>
				</li>
				<li data-section="24"><strong>Equitable relief</strong>
					<ul><li>The Buyer recognises that any breach or threatened breach of the Contract may cause {companyAltName} irreparable harm for which damages may not be an adequate remedy. Accordingly, in addition to any other remedies and damages available to {companyAltName}, the Buyer acknowledges and agrees that {companyAltName} is entitled to the remedies of specific performance, injunction and other equitable relief without proof of special damages.</li></ul>
				</li>
				<li data-section="25" data-c={true}><strong>Severance</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If any provision of these Conditions (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of the Conditions shall not be affected.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">If any provision of the Conditions (or part of any provision) is or becomes illegal, invalid or unenforceable but would be legal, valid and enforceable if some part of it was deleted or modified, the provision or part-provision in question shall apply with the minimum such deletions or modifications as may be necessary to make the provision legal, valid and enforceable. In the event of such deletion or modification, the parties shall negotiate in good faith in order to agree the terms of a mutually acceptable alternative provision.</span></span></li>
					</ol>
				</li>
				<li data-section="26"><strong>Third party rights</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">{companyAltName} is an associated company of Aquaplast Limited. Aquaplast may perform on behalf of {companyAltName} any term under this Contract and shall be entitled to enforce any term of this Contract as if it were {companyAltName}.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">Other than as set out in clause 26.1, a person who is not a party to the Contract shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of the provisions of the Contract.</span></span></li>
					</ol>
				</li>
				<li data-section="27" data-c={true}><strong>Governing law and jurisdiction</strong>
					<ol className="parent n">
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">These Contract and any dispute or claim arising out of, or in connection with, it, its subject matter or formation (including non-contractual disputes or claims) shall be governed by, and construed in accordance with, the laws of England and Wales.</span></span></li>
						<li><span className="list-row"><span className="ndotn-point"></span><span className="ndotn-content">The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, these Contract, its subject matter or formation (including non-contractual disputes or claims).</span></span></li>
					</ol>
				</li>
			</ol>
		</main>
	</div>);
}
