import React, { Fragment, useEffect, useState ,useRef  } from 'react';
import { useSelector } from 'react-redux';
import ProductItem from './product/ProductItem';
import { LoadingSpinner } from './Loading';

export default function PriceListNItems({renderRate, productResults}) {
	const [itemsRendered, setItemsRendered] = useState(renderRate);
	const activeCategory = useSelector(state => state.products.category);
	const searchText = useSelector(state => state.search.text);
	const sortMode = useSelector(state => state.products.sort)
	const ref = useRef();

	const productResultsN = productResults.slice(0, itemsRendered);

	const thereAreMoreItemsToRender = itemsRendered < productResults.length

	useEffect(() => {
		setItemsRendered(renderRate)
	},[activeCategory, searchText, sortMode, renderRate]);

	useEffect(() => {
		const refCurrent = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting && thereAreMoreItemsToRender){
					setItemsRendered(itemsRendered + renderRate);
				}
			},
			{rootMargin: '300px'}
		);
		if(ref.current){
			observer.observe(ref.current);
		}
		return () => {
			observer.unobserve(refCurrent);
		};
	},[productResults.length, renderRate, itemsRendered, thereAreMoreItemsToRender]);

	return(<Fragment>
		{productResultsN.map(item => <ProductItem item={item} key={item.product_code} />)}
		<div ref={ref} className="intersect">
			{thereAreMoreItemsToRender
			? <LoadingSpinner />
			: ""}
		</div>
	</Fragment>);
}
