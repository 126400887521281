import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeFromBasket, updateBasketQuantity } from '../../redux/basketSlice';

import ProductItemExpanded from './ProductItemExpanded';
import ProductNarrative from './ProductNarrative';
import ExpandInfo from '../controls/ExpandInfo';
import QuantityControl from '../controls/QuantityControl';
import { checkIsNumber } from 'utils';

import type { PriceListProduct, ProductWithQuantityAndPrices } from 'pennyuk-business/src';

type BasketItemProps = ProductWithQuantityAndPrices<PriceListProduct>;

export function BasketItem({ product, priceDiscounted, quantity: itemQuantity, subTotal, subTotalWithPromo }: BasketItemProps) {
	const [infoExpanded, setInfoExpanded] =	useState(false);
	const [message, setMessage] = useState('');
	const [quantity, setQuantity] =	useState(itemQuantity);
	const dispatch = useDispatch();

	function validateAndDispatch() {
		var unit = (product.info.unit === 'metre' ? ' metres' : '');
		if(quantity < 1){
			dispatch(removeFromBasket(product.product_code));	
		}else{
			const n = checkIsNumber(quantity);
			const multiple = product.batch ?? 1;
	
			if(n !== undefined && n % multiple === 0){
				dispatch(updateBasketQuantity({code: product.product_code, quantity: n}));
				setMessage('updated to ' + n + unit);
			}else{
				setMessage('Must be multiple of ' + multiple);
			}
		}
	}

	return(<div 
		className="basket-row"
		data-guid={product.id}
		data-productcode={product.product_code}
		data-group={product.item_group}
		data-parent={(!!product.parent ? product.parent : "none")}
		data-tags={product.meta && product.meta.tags ? product.meta.tags.join() : ""}
		data-quantity={itemQuantity}
		data-testid="basket-item"
	>
		<div className="primary">
			<div className="expand-product-details">
				<ExpandInfo infoExpanded={infoExpanded} setInfoExpanded={setInfoExpanded} />
			</div>

			<ProductNarrative
				item={product}
				priceDiscounted={priceDiscounted}
				quantity={itemQuantity}
				showSubTotal={true}
				subTotal={subTotal}
				subTotalWithPromo={subTotalWithPromo}
			/>

			<div className="basket-control">
				<QuantityControl
					code={product.product_code}
					unit={product.info.unit}
					quantity={quantity}
					setQuantity={setQuantity}
					message={message}
				/>
				<button
					type="button"
					name="update order"
					className="update-basket lightblue-rounded-button"
					onClick={() => {
						validateAndDispatch();
					}}
				>
					<span>Update</span>
				</button>
			</div>
		</div>
		{infoExpanded ? <ProductItemExpanded item={product} /> : ''}
	</div>);
}
