import { Fragment } from "react";

import Header from '../Header';
import UserLogin from './UserLogin';
import { getLoggedInUser } from '../../business/rules';
import UserIsLoggedInAs from "./UserIsLoggedInAs";
import { useAppSelector } from "redux/customHooks";

const environment = process.env.NODE_ENV;

export default function UserAccount() {
	const loggedIn = useAppSelector(state => state.user.loggedin);
	const a11yMode = useAppSelector(state => state.user.a11y);

	return(<div id='user-account' className={'nav-page' + (a11yMode ? ' a11y' : '')}>
		<Header title={"User Account"} />
		<aside></aside>
		<main>
			<p>If you need a login please contact us.</p>
			<hr />
			{!loggedIn
			? <UserLogin />
			: <section>
				<h3>Welcome</h3>
				<UserIsLoggedInAs />
				<hr />
				{environment === 'development' ? <TestArea /> : <Fragment />}
			</section>}
		</main>
	</div>);
}

function TestArea() {
	//console.log(cognitoUser.signInUserSession.accessToken.jwtToken);
	//return cognitoUser.getCognitoUserSession();
	//.then(session => console.log(session))
	//.then(session => session.getAccessToken())
	//.then(accessToken => accessToken.getJwtToken())
	//.then(token => console.log(token))
	return(<section title="Dev area">
		<h4><samp>Dev Only area</samp></h4>
		<button
			title='Dev only'
			className='account-button lightblue-rounded-button'
			onClick={() => {
				getLoggedInUser()
				.then(session => {
					session.getIdToken();
					console.log(session);
				})
				.catch(e => console.log('test button ' + e))
			}
				//.then(accessToken => accessToken.getJwtToken())
				//.then(token => console.log(token))
			}
		>
			<samp>Log current session</samp>
		</button>
		<hr />
	</section>);
}
