import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

export default function PwaUpdate() {
	const isUpdated = useSelector(state => state.pwa.updated);
	const serviceWorkerRegistration = useSelector(state => state.pwa.registration);
	if(!isUpdated){
		return <Fragment />
	}else{
		return(<div className="pwa-update">
			<p>There is a web app update available. Click below to update.</p>
			<button
					type="button"
					name="update-pwa"
					className="update-pwa lightblue-rounded-button"
					disabled={false}
					onClick={() => {
						updateServiceWorker(serviceWorkerRegistration);
					}}
				>
				Update
			</button>
		</div>);
	}
}

function updateServiceWorker(serviceWorkerRegistration) {
	try{
		const registrationWaiting = serviceWorkerRegistration.waiting;
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
			registrationWaiting.addEventListener('statechange', e => {
				if(e.target.state === 'activated') {
					window.location.reload();
				}
			});
		}
	}catch(e){
		console.error('error in updateServiceWorker',e);
	}

};
