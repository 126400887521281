import { useSelector } from 'react-redux';
import { selectCataloguedProducts } from '../redux/selectCataloguedProducts';
import PriceListNItems from './PriceListNItems';
import { Loading } from './Loading';
import { Link } from "react-router-dom";
import TrafficLight from './product/TrafficLight';

export default function PriceListContent() {
	const productFetchStatus = useSelector(state => state.products.status);
	const fetchError = useSelector(state => state.products.error);
	const productResults = useSelector(state => selectCataloguedProducts(state));

	if(productFetchStatus !== 'succeeded'){
		return(<section className="price-list">
			<Loading status={productFetchStatus} error={fetchError} />
		</section>);
	}else{
		return(<section className="price-list">
			<div className="stock-indicator-key">
				<h2 className="stock-indicator-heading">Stock Indicator Key</h2>
				<span className="stock-indicator-legend amber"><TrafficLight title="Product may not be available.  Please contact us." colour={"AMBER"} /><small>&nbsp;Product may not be available.</small></span>
				<span className="stock-indicator-legend green"><TrafficLight colour={"GREEN"} title="Product is in stock." /><small>&nbsp;Product is in stock.</small></span>
				<small className="stock-indicator-disclaimer"><i>Stock indicators are for guidance only. If products are urgent, please <span className="line"><Link to="/contact" className="contact-link">consult with the sales office</Link></span>.</i></small>
			</div>

			<p>Results: {productResults.length} items</p>

			<PriceListNItems productResults={productResults} renderRate={50} />
		</section>);
	}
}
