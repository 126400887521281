import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import { Loading } from 'components/Loading';
import Agreement from 'components/Agreement';
import { setAgreedToTerms } from 'redux/userSlice';

import type { AsyncStatus } from 'business/types';

export function BasketLoading({ status }: { status: AsyncStatus }) {
	return(
		<div id="basket" data-basket-state="Loading">
			<Header title={"Basket"} />
			<main className="item-list">
				<Loading status={status} />
			</main>
		</div>
	);
}

export function BasketEmpty({ status }: { status: 'idle' | 'sent' | 'sending' | 'failed' }) {
	return(<div id="basket" data-basket-state="Empty" className='nav-page'>
		<Header title={"Basket"} />
		<main className="item-list basket-empty">	
			<p>{(status === 'sent' ? <b>Order Sent<br /></b> : "")}Basket empty.</p>
			<p><i>If you have encountered any problems, </i><Link to="/contact" className="contact-link">please let us know</Link><i> or try our </i><Link to="/help" className="help-link">help page</Link></p>
		</main>
	</div>);
}

export function BasketAgreement() {
	const dispatch = useDispatch();

	return(<div id="basket" data-basket-state="Agreement" className='nav-page'>
		<Header title={"Basket"} />
		<main className="agreement">
			<Agreement
				name={"Our Terms & Conditions"}
				onAgree={() => {
					const whenAgreed = Date.now();
					dispatch(setAgreedToTerms(whenAgreed));
					window.localStorage.setItem("PukTermsAgreed", whenAgreed.toString());
				}}
				link="/terms-conditions"
			/>
		</main>
	</div>);
}
