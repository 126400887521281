import { useState, useEffect } from 'react';

import { sanitiseCustomerDetails } from 'utils';
import type { CustomerDetails } from 'business/types';

type OrderCustomerDetailsProps = {
	email: string;
	setEmail: (value: React.SetStateAction<string>) => void;
	setDetails: (value: React.SetStateAction<CustomerDetails>) => void;
}

export function OrderCustomerDetails({ email, setEmail, setDetails }: OrderCustomerDetailsProps) {
	const [name, setName] = useState('');
	const [company, setCompany] = useState('');
	const [address, setAddress] = useState('');
	const [notes, setNotes] = useState('');

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			setDetails({
				name: sanitiseCustomerDetails(name),
				company: sanitiseCustomerDetails(company),
				address: sanitiseCustomerDetails(address),
				notes: sanitiseCustomerDetails(notes)
			})	
		}, 300);

		return () => clearTimeout(delayDebounce)
	}, [name, company, address, notes, setDetails])

	// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#inappropriate-for-the-control

	return(<fieldset>
		<legend><small>Please enter your details.</small></legend>
		<div className="form-group">
			<label htmlFor="formEmail">
				Email address
			</label>
			<input
				id="formEmail"
				className="field"
				name="email"
				placeholder="Enter email"
				autoFocus
				type="email"
				autoComplete="email"
				value={email}
				onChange={e => {
					setEmail(e.target.value);
				}}
			/>
			<label htmlFor="formName">
				Name
			</label>
			<input
				id="formName"
				className="field"
				name="name"
				placeholder="Enter Name"
				type="text"
				value={name}
				autoComplete="name"
				onChange={e => {
					setName(e.target.value);
				}}
			/>
			<label htmlFor="formCompany">
				Company
			</label>
			<input
				id="formCompany"
				className="field"
				name="company"
				placeholder="Enter Company"
				type="text"
				value={company}
				autoComplete="organization"
				onChange={e => {
					setCompany(e.target.value);
				}}
			/>
		</div>
		<div className="form-group">
			<label htmlFor="formDeliveryAddress">
				Delivery Address
			</label>
			<br />
			<textarea
				id="formDeliveryAddress"
				className="field"
				name="deliveryAddress"
				placeholder="Enter Delivery Address"
				autoComplete="street-address"
				value={address}
				rows={10}
				cols={30}
				onChange={e => {
					setAddress(e.target.value);
				}}
			/>
		</div>
		<div className="form-group">
			<label htmlFor="formNotes">
				Other details (eg: internal order number)
			</label>
			<br />
			<textarea
				id="formNotes"
				className="field"
				name="notes"
				placeholder="Enter Notes"
				autoComplete="off"
				value={notes}
				rows={10}
				cols={30}
				onChange={e => {
					setNotes(e.target.value);
				}}
			/>
		</div>
	</fieldset>);
};


