import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import PwaUpdate from './pwa/Update';

const companyFullName = process.env.REACT_APP_COMPANY_FULL_NAME;

export default function Header({title}) {
	const offline = useSelector(state => state.pwa.offline);

	function getMessage(status) {
		switch(status){
			/*case true:
				return(<p>Service worker installed</p>);*/
			case true:
				return(<p><samp><b>No internet connection</b>. You may continue to use the web app while offline.</samp></p>);
			case false:
				return(<Fragment />);
				//return(<p><samp>Online</samp></p>);
			default:
				return(<Fragment />);
		}
	}

    return(
		<header >
			<Link to="/">
				<img src={process.env.PUBLIC_URL + "/company-logo-full.png"} className="company-logo" alt={companyFullName + " logo"} />
			</Link>
			<div className="row">
				<h1 className="page-title">{title}</h1>
				<section className="messages">
					{getMessage(offline)}
					<PwaUpdate />
				</section>
			</div>
		</header>
    );
}
