import { Auth, type CognitoUser} from '@aws-amplify/auth';

export const matchRcode = /R[ANX]X?\d{4}\w{0,2}/g;

export function matchValidPassword(password: string) {
	return /^(?=.*[0-9])(?=.*[a-z])([a-zA-Z0-9]+)$/.test(password);
}

const isLocal = process.env.NODE_ENV === 'development'

export function loginConfig() {
	Auth.configure({
		region: process.env.REACT_APP_AWS_REGION,
		userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL,
		userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
		identityPoolId: process.env.REACT_APP_AWS_COGNITIO_IDENTITY_POOL,
		oauth: {
			domain: 'auth.pennyukltd.com',
			scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], // ['email', 'openid'], 
			redirectSignIn: isLocal
				? 'http://localhost:3000/user-account'
				: `https://${window.location.hostname}/user-account`,
			redirectSignOut: isLocal
				? 'http://localhost:3000'
				: `https://${window.location.hostname}`,
			responseType: "code",
		},
	})
}

export async function checkForLoggedInUser() {
	//return Auth.currentSession()
	const user = await Auth.currentAuthenticatedUser()

	if(user) {
		return user as CognitoUser
	}
}

export async function getUserAttributes(user: CognitoUser) {
	return Auth.userAttributes(user);
}

export function getLoggedInUser() {
	return Auth.currentSession();
}

export function logout() {
	Auth.signOut()
		.then(() => {window.location.reload()})
}

export function forgotPassword(email: string) {
	Auth.forgotPassword(email)
		.then(() => alert('Request submitted.  Please check your email.'))
		.catch(e => alert(e.message))
}

export function adminResetPassword(email: string) {
	Auth.forgotPassword(email)
		.then(() => {window.location.reload()})
		.catch(e => alert(e.message))
}

export function forgotPasswordSubmit(email: string, changeCode: string, newPassword: string, toggleLoggedInCb: () => void) {
	Auth.forgotPasswordSubmit(email, changeCode, newPassword)
		.then(() => {
			alert('Password changed successfully.');
			toggleLoggedInCb();
		})
		.catch(e => alert(e.message))
}

export function signIn(email: string, password: string, toggleNewPassCb: (toggle: boolean) => void, loggedInCb: (toggle: boolean) => void) {
	Auth.signIn(email, password)
		.then((cognitoUser: CognitoUser) => {
			if(cognitoUser.challengeName) {
				if(cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'){
					toggleNewPassCb(true);
				}
			}else{
				loggedInCb(true);
			}
		})
		.catch(err => alert('Error '+err.message));
}

export function signInOnPasswordChange(email: string, oldPassword: string, newPassword: string, callStore: (toggle: boolean) => void) {
	/* MUST use .completeNewPassword for user created by Admin */
	Auth.signIn(email, oldPassword)
		.then((cognitoUser: CognitoUser) => {
			return Auth.completeNewPassword(cognitoUser, newPassword)
		})
		.then((cognitoUser: CognitoUser) => {
			if(Object.prototype.hasOwnProperty.call(cognitoUser, 'Session')){
				callStore(true);
			}else{
				throw new Error('Problem setting new password.');
			}
		})
		.then(() => {window.location.reload()})
		.catch(err => alert(err.message));
}
