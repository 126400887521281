import React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';

const companyPhone = process.env.REACT_APP_CONTACT_PHONE
const companyEmail = process.env.REACT_APP_CONTACT_EMAIL;
const companyFullName = process.env.REACT_APP_COMPANY_FULL_NAME;

export default function Contact() {
	const a11yMode = useSelector(state => state.user.a11y);
	return (<div id='contact' className={'nav-page' + (a11yMode ? ' a11y' : '')}>
		<Header title={"Contact Us"} />
		<main className="">
			<div className="">
				<h2>We're looking forward to hearing from you</h2>
				<p>If you have any queries, would like to place an order, or indeed, give any feedback please contact us:</p>
				<p>Phone:&nbsp;<strong><a href={"tel:+44"+process.env.REACT_APP_CONTACT_PHONE}>0{companyPhone}</a></strong></p>
				<p>Email:&nbsp;<strong><a href={"mailto:"+process.env.REACT_APP_CONTACT_EMAIL}>{companyEmail}</a></strong></p>
				<p>We will aim to respond within the hour!</p>
				<p>By Post:<br />
				<strong>{companyFullName}</strong><br />
				Units 16-18 Aston Fields Road<br />
				Whitehouse Industrial Estate<br />
				Preston Brook<br />
				Cheshire<br />
				WA7 3DL</p>
			</div>
			<div className="">
			</div>
		</main>
	</div>);
}
