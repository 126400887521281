import { useEffect } from 'react';

import { FEATURES } from 'business/featureSwitcher';
import Header from 'components/Header';
import { OrderPromoCode } from 'components/order/OrderPromoCode'
import { OrderSend }  from 'components/order/OrderSend';
import { BasketItem } from 'components/product/BasketItem';
import { BasketLoading, BasketEmpty, BasketAgreement } from 'components/order/OrderMisc';
import { selectAllInBasket, clearBasket, setBasketStatusIdle } from 'redux/basketSlice';
import { useAppDispatch, useAppSelector } from 'redux/customHooks';
import { selectBasketProductsAndTotal } from 'redux/selectBasketProductsAndTotal';

export default function OrderBasket() {
	const dispatch = useAppDispatch();
	const productFetchStatus = useAppSelector(state => state.products.status);
	const itemsInBasket = useAppSelector(selectAllInBasket);
	const agreedTerms = useAppSelector(state => state.user.agreedTerms);
	const orderSendStatus = useAppSelector(state => state.basket.status);

	useEffect(() => {
		if(itemsInBasket.length === 0){
			setTimeout(() => {
				dispatch(setBasketStatusIdle());
			}, 10000);
		}
	},[itemsInBasket]) //eslint-disable-line react-hooks/exhaustive-deps

	if (!process.env.REACT_APP_TERMS_DATE) {
		throw new Error('REACT_APP_TERMS_DATE not set');
	}

	const currentTermsEffectiveDate = new Date(process.env.REACT_APP_TERMS_DATE);

	if(!agreedTerms || agreedTerms < currentTermsEffectiveDate.getTime()){
		return(<BasketAgreement />);
	}

	if(productFetchStatus !== 'succeeded'){
		return(<BasketLoading status={productFetchStatus} />);
	}

	if(itemsInBasket.length === 0){
		return(<BasketEmpty status={orderSendStatus} />);
	}

	return(
		<BasketItemsAndOrderForm />
	);
}

function BasketItemsAndOrderForm() {
	const dispatch = useAppDispatch();
	const a11yMode = useAppSelector(state => state.user.a11y);
	const basketProductsAndTotal = useAppSelector(selectBasketProductsAndTotal);

	return (
		<div id="basket" data-basket-state="Listing" className={"nav-page" + (a11yMode ? " a11y" : "")}>
			<Header title={"Basket"} />
			<main className={"item-list"}>
				<div className="basket-row headers">
					<div className="basket-expand-button"></div>
					<div className="basket-item-details">{/*<h4>Product Information</h4>*/}</div>
					<div className=""></div>
					<div className="basket-quantity">{/*<h4>Qty</h4>*/}</div>
					<div className="basket-control"></div>
				</div>
				{basketProductsAndTotal.basketProducts.map(basketProduct => (
					<BasketItem
						product={basketProduct.product}
						quantity={basketProduct.quantity}
						subTotal={basketProduct.subTotal}
						subTotalWithPromo={basketProduct.subTotalWithPromo}
						key={basketProduct.product.product_code}
					/>
				))}
			</main>
			<aside>
				{FEATURES.promos && (
					<>
						<hr />
						<OrderPromoCode />
					</>
				)}
				<hr />
				<div className='row'>
					<BasketTotalDisplay
						basketTotalCost={basketProductsAndTotal.basketTotalCost}
						basketTotalCostWithPromoApplied={basketProductsAndTotal.basketTotalCostWithPromoApplied}
					/>
					<button
						type="button"
						name="clear basket"
						className="clear-basket lightblue-rounded-button"
						onClick={() => {
							if (window.confirm("Clear all items from basket?")) { 
								dispatch(clearBasket());
							}
						}}
					>
						<span>Clear Basket</span>
					</button>
				</div>
				<hr />
				<OrderSend />
			</aside>
		</div>
	);
}

function BasketTotalDisplay({ basketTotalCost, basketTotalCostWithPromoApplied }: { basketTotalCost: string; basketTotalCostWithPromoApplied?: string; }) {
	if(basketTotalCostWithPromoApplied) {
		return (
			<div>
				<data className='total-cost' value={basketTotalCost}>
					<span className='basket-total-label'>Total before promo</span> <span>£{basketTotalCost}</span>
				</data>
				<hr />
				<data className='total-cost' value={basketTotalCostWithPromoApplied}>
					<b className='basket-total-label'>Total</b> <span>£{basketTotalCostWithPromoApplied}</span>
				</data>
			</div>
		);
	}

	return (
		<data className='total-cost' value={basketTotalCost}>
			<b className='basket-total-label'>Total</b> £{basketTotalCost}
		</data>
	);
}
