import React from 'react';

export default function QuantityControl({code, unit, quantity, setQuantity, message}) {
	const minqty = 1;
	const maxqty = 9999999;

	return(<div className="quantity">
		<label htmlFor={"quantity-"+code}>
			{unit === 'metre' ? 'Metres' : 'Quantity'}<br />
			<span className="message">{message}</span>
		</label>
		<input
			id={"quantity-"+code}
			type="number"
			name="quantity"
			className="order-quantity"
			min={minqty} max={maxqty}
			value={quantity}
			onChange={event => {
				setQuantity(event.target.value);
			}}
		/>
	</div>);
}
