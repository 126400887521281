import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import CategoriesMenu from './CategoriesMenu';
import SortControl from './controls/SortControl';
import SearchBar from './controls/SearchBar';
import PriceListContent from './PriceListContent';
import SearchMode from './controls/SearchMode';
import LayoutControls from './controls/LayoutControls';

export default function PriceListHome() {
	const a11yMode = useSelector(state => state.user.a11y);
	const [productControls, setProductControls] = useState(true);
	const [showCategories, setShowCategories] = useState(true);

	return(<div id='home' className={"nav-page" + (a11yMode ? ' a11y' : '') + (!productControls ? " hide-controls" : " show-controls")}>
		<Header title={"Price List"} />
		<LayoutControls
			productControls={productControls}
			setProductControls={setProductControls}
			showCategories={showCategories}
			setShowCategories={setShowCategories}
		/>
		<aside>
			<CategoriesMenu hide={!showCategories} />
			<SearchBar />
			<div className="inputs">
				<SortControl />
				<SearchMode />
			</div>
		</aside>
		<main>
			<PriceListContent />
		</main>
	</div>);
}
