import { createSelector } from '@reduxjs/toolkit';
import { roundToDecimalPlaces } from 'pennyuk-business/src';

import { categoryData, Slugs } from 'business/categoryData';
import { sortOptions, compareSearchTextWithProduct } from 'utils';
import { RootState } from 'redux/store';

import type { PriceListProduct } from 'pennyuk-business/src';

export const selectCataloguedProducts = createSelector<
	Array<(state: RootState) => any>,
	Array<PriceListProduct & { priceDiscounted: number; }>
>(
	[
		state => state.products.ids,
		state => state.products.entities,
		state => state.products.category,
		state => state.search.text,
		state => state.search.mode,
		state => state.products.sort,
		state => state.user.discounts,
		state => state.user.products
	],
	(productIds, productEntities, category, searchText, searchMode, sort, discounts: Record<number, number> | null, userProducts) => {
		const isCategorySet = (category !== "/");
		const isSearchTextSet = (!!searchText || searchText.length > 0);

		const codesFiltered = filterProductCatalogue(productIds, productEntities, isCategorySet, isSearchTextSet, categoryData[category as Slugs], searchText, searchMode);

		const productDataForSorting = codesFiltered.map(code => {
			const product = productEntities[code];

			if(userProducts && code in userProducts){
				return {
					...product,
					...userProducts[code]
				};
			}

			const discount = discounts?.[product.item_group];

			if(discount){
				return {
					...product,
					priceDiscounted: roundToDecimalPlaces(product.price * (1 - discount), 4),
				}
			}

			return product;
		});

		return productDataForSorting.sort(sortOptions(sort));
	}
);

function filterProductCatalogue(
	productIds: string[],
	productEntities: Record<string, PriceListProduct>,
	isCategorySet: boolean,
	isSearchTextSet: boolean,
	categoryItem: { item_groups: number[]; },
	searchText: string,
	searchMode: 'all' | 'any',
): string[] {
	if(!isCategorySet && !isSearchTextSet){
		return productIds;
	}

	if(isCategorySet && !isSearchTextSet){
		return productIds.filter(productCode => {
			return categoryItem.item_groups.includes(productEntities[productCode].item_group);
		});
	}

	if(!isCategorySet && isSearchTextSet){
		return productIds.filter(productCode => {
			return compareSearchTextWithProduct(searchText, productEntities[productCode], searchMode);
		});
	}

	return productIds.filter(productCode => {
		const product = productEntities[productCode];
		return compareSearchTextWithProduct(searchText, product, searchMode)
		&& categoryItem.item_groups.includes(product.item_group);
	});
}
