import { formatPriceWithTrailingZeros } from 'business/money';

type PriceDisplayProps = {
	price: number;
	priceDiscounted?: number; 
	subTotal?: number;
	showSubTotal: boolean;
	displayUnitPriceDiscounted?: boolean;
}

export default function PriceDisplay({ price, priceDiscounted, subTotal, showSubTotal, displayUnitPriceDiscounted }: PriceDisplayProps) {
	if(!!showSubTotal && subTotal){
		return(
			<PriceDiscountedSubtotal
				price={price}
				priceDiscounted={priceDiscounted}
				subTotal={subTotal}
				displayUnitPriceDiscounted={displayUnitPriceDiscounted}
			/>
		);
	}

	if(priceDiscounted && displayUnitPriceDiscounted){
		return(
			<div className="price">
				<Price title="price, discount applied" price={priceDiscounted} />
			</div>
		);
	}

	return(<div className="price">
		<Price title="price" price={price} />
	</div>);
}

function Price({price, title}: { price: number, title: string; }){
	return(<data title={title} value={price}>{formatPriceWithTrailingZeros(price)}</data>);
}

function PriceDiscountedSubtotal({ price, subTotal, priceDiscounted, displayUnitPriceDiscounted }: { price: number, priceDiscounted?: number; subTotal: number, displayUnitPriceDiscounted?: boolean }) {
	return(
		<div className="price-and-subtotal">
			<Price title="price per unit" price={priceDiscounted && displayUnitPriceDiscounted ? priceDiscounted : price} />
			<Price title="subtotal" price={subTotal} />
		</div>
	);
}
