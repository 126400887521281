import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PriceListProduct } from 'pennyuk-business/src';
import { getLoggedInUser } from '../business/rules';
import type { AsyncStatus } from 'business/types';

type UserState = {
	email: string;
	name: string;
	access: {
		admin: boolean;
		discounts: boolean;
		changePL: boolean;
	}
	discounts: Record<number, number> | null;
	products: Record<string, Partial<PriceListProduct>> | null;
	loggedin: boolean;
	newPassNeeded: boolean;
	agreedTerms: number | null;
	a11y: boolean;
	productInfoExpanded: boolean;
	status: AsyncStatus;
	error: string | null;
}

const initialState: UserState = {
	email: '',
	name: '',
	access: {
		admin: false,
		discounts: false,
		changePL: false
	},
	discounts: null,
	products: null,
	loggedin: false,
	newPassNeeded: false,
	agreedTerms: null,
	a11y: false,
	productInfoExpanded: false,
	status: 'idle',
	error: null,
};

export function userAccountPreloadedState() {
	try {
		const localStoredTermsAgreed = window.localStorage.getItem("PukTermsAgreed");
		const localStoredA11y = window.localStorage.getItem("PukA11y");
		const localStoredInfoExpand = window.localStorage.getItem("PukProductInfoExpanded");

		const preloadedState = {
			...initialState,
			agreedTerms: Number(localStoredTermsAgreed) ?? 0,
			a11y: !localStoredA11y ? false : localStoredA11y.toLowerCase() === "true",
			productInfoExpanded: !localStoredInfoExpand ? false : localStoredInfoExpand.toLowerCase() === "true"
		}
		return preloadedState;
	} catch(e) {
		console.warn(e);
		return initialState;
	}
}

export const fetchDiscounts = createAsyncThunk('user/fetchDiscounts', () => {
	return getLoggedInUser()
		.then(session => session.getIdToken())
		.then(idToken => {
			return fetch(
				process.env.REACT_APP_API + '/discounts',
				{headers: { 'Authorization': idToken.getJwtToken() }}
			)
		})
		.then(res => {
			if(!res.ok) {
				console.log('Network response was not ok');
				throw new Error('Network response was not ok');
			}else if((res.status < 200 || res.status >= 300)){
				console.log('Network responded with '+res.status);
				throw new Error('Network responded with '+res.status);
			}else{
				return res.json();
			}
		})
		.then(res => JSON.parse(res.body));
});

export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setUser: (state, action: { payload: { email: string; name: string } }) => {
			state.loggedin = true;
			state.email = action.payload.email;
			state.name = action.payload.name;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setAccess: (state, action) => {
			switch(action.payload){
				case "1":
					state.access = {
						admin: true,
						discounts: true,
						changePL: false
					};
					break;
				case "2":
					state.access = {
						admin: false,
						discounts: true,
						changePL: false
					};
					break;
				case "3":
					state.access = {
						admin: true,
						discounts: false,
						changePL: false
					};
					break;
				case "4":
					state.access = {
						admin: true,
						discounts: true,
						changePL: true
					};
					break;
				default:
					state.access = {
						admin: false,
						discounts: false,
						changePL: false
					};
			}
		},
		toggleLoggedIn: (state, action) => {
			state.loggedin = !!action.payload;
		},
		toggleNewPassNeeded: (state, action) => {
			state.newPassNeeded = action.payload;
		},
		clearDiscounts: state => {
			state.discounts = null;
			state.products = null;
		},
		setAgreedToTerms: (state, action) => {state.agreedTerms = action.payload;},
		toggleA11y: (state, action) => { state.a11y = action.payload;},
		toggleAllProductInfoExpanded: (state, action) => {
			state.productInfoExpanded = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDiscounts.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(fetchDiscounts.fulfilled, (state, action) => {
			state.status = 'succeeded';
			state.discounts = action.payload.results;
			state.products = "products" in action.payload ? action.payload.products : null;
		});
		builder.addCase(fetchDiscounts.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message ?? 'no error message';
			state.discounts = null;
			state.products = null;
		});
	}
});

export const {
	setEmail,
	setName,
	setAccess,
	setUser,
	toggleLoggedIn,
	toggleNewPassNeeded,
	clearDiscounts,
	setAgreedToTerms,
	toggleA11y,
	toggleAllProductInfoExpanded
} = userSlice.actions;
export default userSlice.reducer;
