import React from 'react';
import Header from './Header';
import { Link } from "react-router-dom";

const companyWebAddress = process.env.REACT_APP_COMPANY_DOMAIN;
const companyFullName = process.env.REACT_APP_COMPANY_FULL_NAME;
const companyAltName = process.env.REACT_APP_COMPANY_ALT_NAME;

export default function SecurityAndPrivacy() {
	return (<div id='security-privacy' className={"nav-page"}>
		<Header title={companyFullName + " Security and Privacy Policy"} />
		<main>
			<p>{companyAltName} ensures we comply with all applicable privacy and data protection laws.</p>
			<p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from <Link to="/">pricelist.{companyWebAddress}</Link> (the "Site").</p>
			<section title="Definitions">
				<h2>Definitions</h2>
				<p>In this Privacy Policy, the following definitions shall apply:</p>
				<dl>
					<dt><h4>Website</h4></dt>
					<dd>'Website', 'site', 'web app' and 'pricelist web app' refer to <Link to="/">pricelist.{companyWebAddress}</Link>. For the purposes of this Privacy Policy, <Link to="/">pricelist.{companyWebAddress}</Link> is a distinct, separate entity from <a target="_blank" rel="noopener noreferrer" href={`https://${companyWebAddress}`}>{companyWebAddress}</a> and <a target="_blank" rel="noopener noreferrer" href={`https://www.${companyWebAddress}`}>www.{companyWebAddress}</a>.</dd>
					<dt><h4>{companyAltName}</h4></dt>
					<dd>'We', '{companyFullName}' and 'Penny UK' means Penny Irrigation UK Limited, a private limited company incorporated in England and Wales with company number 03022043 and whose registered office is Oakfield Plumley Moor Road, Plumley, Knutsford, England, WA16 9RS.</dd>
					<dt><h4>Product</h4></dt>
					<dd>Any item that can be chosen from <Link to="/">pricelist.{companyWebAddress}</Link> using an add to order button.</dd>
					<dt><h4>Applicable Laws</h4></dt>
					<dd>'Applicable Laws' mean means all applicable laws, legislation, statutory instruments, regulations and governmental guidance having binding force whether local or national;</dd>
					<dt><h4>User Account</h4></dt>
					<dd>If you are a customer of {companyAltName}, you may have requested a User Account.  This will be sent from an automated service to the email address you provide along with a temporary password.  You may use this to login at <Link to="/user-account">pricelist.{companyWebAddress}/user-account</Link>. {companyAltName} does not store any password and is not able to view any password temporary or otherwise.  If you do not receive User Account emails, please contact us. You may change or reset your password at <Link to="/user-account">pricelist.{companyWebAddress}/user-account</Link> by following the instructions found there. Your email and password are otherwise solely your own responsibility.</dd>
					<dt><h4>Local Storage</h4></dt>
					<dd>Local Storage is a technology utilised by <Link to="/">pricelist.{companyWebAddress}</Link>.  This is a form of web storage used to store data on a user's device in their web browser. Local Storage is standardized by the World Wide Web Consortium (W3C) and the Web Hypertext Application Technology Working Group (WHATWG). All major browsers support it.</dd>
				</dl>
			</section>
			<section title="how Data Is Used By The Website">
				<h2>How Data Is Used By The Website</h2>
				<p>When you visit <Link to="/">pricelist.{companyWebAddress}</Link> we store information in your web browser as follows</p>
				<ol>
					<li>Product codes and quantities for any Product for which you have clicked 'add to order' are recorded in Local Storage.  This allows us to provide a persistent basket which can be returned to after closing and reopening your browser.</li>
					<li>If you have a User Account with us, information is stored in Local Storage on login.  This may include personal details such as email and address if you have provided these so that the Website can provide a tailored user experience.</li>
					<li>User settings, such as hiding/showing controls may be persisted in Local Storage so that the setting states are saved for your next visit.</li>
				</ol>
				<h3>Logs</h3>
				<p>During a visit, logs are created by the service hosting the website.  This data is anonymised and not used to identify users.</p>
				<h3>Cookies</h3>
				<h4>HubSpot chat</h4>
				<p>Our live chat is provided by HubSpot.  The live chat will set cookies necessary for it to function.<br />
				The HubSpot privacy policy may be viewed here - <a href="https://legal.hubspot.com/privacy-policy">legal.hubspot.com/privacy-policy</a></p>
				<p>Cookies are not otherwise utilised.<br />
				The website uses Browser Local Storage as it provides a more secure user experience.  Unlike cookies, information in Local Storage is not automatically sent to the website hosting service.  However, this information may be explicitly sent to the website hosting service, such as using a stored email address when a user makes an order.</p>
			</section>
			<section title="What rights you have over your data">
				<h2>What rights you have over your data</h2>
				<p>If you have a User Account with us, you can request to receive a file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
			</section>
			<section title="Contact">
				<p>Please contact us using the <Link to="/contact">contact link</Link> with any data protection or privacy queries.</p>
			</section>

		</main>
	</div>);
}
