import { createSlice, createAsyncThunk, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import type { AsyncStatus } from 'business/types';
import type { Slugs } from 'business/categoryData';
import type { PriceListProduct } from 'pennyuk-business/src';

const apiUrl = process.env.REACT_APP_API;
const companyCode = process.env.REACT_APP_COMPANY_SHORT_NAME;
const stageFromDeployEnv = process.env.REACT_APP_DEPLOY_STAGE;

const productsAdapter = createEntityAdapter({
	selectId: (product: PriceListProduct) => product.product_code,
	sortComparer: (a, b) => {
		const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
		if(nameA < nameB) return -1;
		if(nameA > nameB) return 1;

		return 0;
	}
});

type ProductsState = {
	category: Slugs | '/';
	productsTimeStamp: number | null
	sort: string;
	status: AsyncStatus;
	error: string | null;
}

const initialState = productsAdapter.getInitialState<ProductsState>({
	category: '/',
	productsTimeStamp: null,
	sort: 'default',
	status: 'idle',
	error: null,
});

type ProductResponse = {
	results: Array<PriceListProduct>
	stage: string //"prod"
	count: number //2494
	version: string //"1.3.18"
	override: null // override the current date to test future prices
	timestamp: string //"1/20/2024 7:56:59 PM"
	timetampms: number
}

export const fetchProducts = createAsyncThunk<ProductResponse>('products/fetchProducts', async () => {
	const response = await fetch(`${apiUrl}?c=${companyCode ?? ''}`);

	if(!response.ok){
		throw new Error('Network response was not ok');
	}

	const contentType = response.headers.get('content-type');

	if(!contentType || !contentType.includes('application/json')){
		throw new TypeError("fetchProducts did not receive JSON");
	}

	if(stageFromDeployEnv !== 'prod'){
		console.log("fetchProducts completed");
	}

	return response.json();
});

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		changeSort: (state, action) => {
			state.sort = action.payload;
		},
		changeSlug: (state, action) => {
			state.category = action.payload;
		},
		clearSlug: state => {
			state.category = '/';
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchProducts.pending, state => {
			state.status = 'loading';
		});
		builder.addCase(fetchProducts.fulfilled, (state, action) => {
			state.status = 'succeeded';
			state.productsTimeStamp = action.payload.timetampms
			productsAdapter.upsertMany(state, action.payload.results);
		});
		builder.addCase(fetchProducts.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message ?? 'no error message';
		});
	}
});

export default productsSlice.reducer;
export const { changeSort, changeSlug, clearSlug } = productsSlice.actions;
export const {
	selectAll: selectAllProducts,
	selectById: selectProductByCode,
	selectIds: selectProductCodes,
} = productsAdapter.getSelectors<{ products: EntityState<PriceListProduct> & ProductsState }>((state) => state.products);
