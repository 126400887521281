import IntersectionObserver from 'intersection-observer'; // eslint-disable-line no-unused-vars
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { loginConfig } from './business/rules';

loginConfig();

const root = createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

if(!!process.env.REACT_APP_DEPLOY_STAGE && process.env.REACT_APP_DEPLOY_STAGE === 'prod'){
	serviceWorkerRegistration.register({
		onSuccess: () => {
			store.dispatch({ type: "pwa/init" });
		},
		onUpdate: reg => {
			store.dispatch({ type: "pwa/registration", payload: reg });
			store.dispatch({ type: "pwa/updated", payload: true });
		},
	});
}else{
	serviceWorkerRegistration.unregister();
}
