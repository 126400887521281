import React, { Fragment } from 'react';

export default function ProductItemPicture({item}) {
	if(!item.image || !item.image.url || item.image.url.trim().length === 0){
		return(<Fragment />);
	}else{
		const companyDomain = process.env.REACT_APP_COMPANY_DOMAIN;
		const deployStage = process.env.REACT_APP_DEPLOY_STAGE;

		const image = item.image;
		const pathPrefix = (deployStage !== 'prod' ? `https://pricelist.${companyDomain}` : '');
		const imageAlt = ((!image.alt || image.alt.trim().length === 0)
			? item.name
			: image.alt);

		if(!image.srcset){
			return(<img
				src={pathPrefix + image.url}
				alt={imageAlt}
			/>);
		}else{
			const srcsetKeys = Object.keys(image.srcset);
			const srcsetValue = srcsetKeys.reduce((acc,item,i) => {
				return `${(i === 0 ? '' : ',')}${acc}${pathPrefix}${image.srcset[item]} ${item}`
			},'');

			return(<img
				src={pathPrefix + image.url}
				srcSet={srcsetValue}
				sizes="(min-width: 768px) 25vw, 50vw"
				alt={imageAlt}
			/>);
		}
	}

	/*if(!!item.image.picture && !!item.image.srcset){
		return(<picture>
			{!!item.image.picture.webp ? <source type="image/webp" srcset={pathPrefix + item.image.webp} /> : <Fragment />}
			<img
				src={pathPrefix + item.image.url}
				srcset={pathPrefix + item.image.srcset}
				sizes="(min-width: 768px) 25vw, 50vw"
				alt={imageAlt}
			/>
		</picture>);
	}*/
}
