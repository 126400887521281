import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/customHooks';
import { sendOrder, selectAllInBasket } from 'redux/basketSlice';
import { sanitiseEmailAddress } from 'utils';
import { OrderCustomerDetails } from 'components/order/OrderCustomerDetails';

import type { CustomerDetails } from 'business/types';

const companyCode = process.env.REACT_APP_COMPANY_SHORT_NAME;

function showOrderStatusMessage(status: string, isOffline: boolean) {
	if(isOffline === true){
		return 'Offline';
	}
	switch(status){
		case 'sent':
			return 'Order Sent';
		case 'sending':
			return 'Sending Order';
		case 'failed':
			return 'Order Failed';
		default:
			return 'Send Order'
	}
}

export function OrderSend() {
	const [email, setEmail] = useState('');
	const [safeEmail, setSafeEmail] = useState('');
	const [details, setDetails] = useState<CustomerDetails>({});
	const [termsCheck, setTermsCheck] = useState(false);

	const dispatch = useAppDispatch();
	const loggedInUserEmail = useAppSelector(state => state.user.email);
	const orderSendStatus = useAppSelector(state => state.basket.status);
	const userLoggedIn = useAppSelector(state => state.user.loggedin);
	const itemsInBasket = useAppSelector(selectAllInBasket);
	const offline = useAppSelector(state => state.pwa.offline);
	const promoCode = useAppSelector(state => state.basket.promo?.code) 

	useEffect(() => {
		if(loggedInUserEmail.length > 0){
			setEmail(loggedInUserEmail)
		}
	},[]); //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			if(email.length > 0){
				setSafeEmail(sanitiseEmailAddress(email))
			}
		}, 500);
		return () => clearTimeout(delayDebounce)
	},[email, setSafeEmail]);

	if (!companyCode) {
		throw new Error('REACT_APP_COMPANY_SHORT_NAME not set');
	}

	return(
		<section className='send-order'>
			<form>
				<OrderCustomerDetails
					email={email}
					setEmail={setEmail}
					setDetails={setDetails}
				/>
				<div className="checkout">
					<label htmlFor="termsAgree">Please confirm you agree to our <Link to="/terms-conditions" className="terms-conditions-link">Terms &amp; Conditions</Link> before ordering.</label>
					<input
						type="checkbox"
						id="termsAgree"
						className="terms-agree"
						name="termsAgreeCheck"
						value="Agreed"
						checked={termsCheck}
						onChange={() => {
							setTermsCheck(!termsCheck)
						}}
					/>
					<button
						type="submit"
						disabled={
							offline
							|| orderSendStatus === 'sending'
							|| email.length === 0
							|| safeEmail.length === 0
							|| !itemsInBasket
							|| !termsCheck
						}
						name={"send-order"}
						title={"Send Order"}
						className="lightblue-rounded-button prominent my-3 send-order"
						onClick={e => {
							try{
								if(offline){
									alert('You are offline. Order not sent.');
								}else{
									e.preventDefault();
									dispatch(sendOrder({
										brand: companyCode,
										products: itemsInBasket,
										email: safeEmail,
										details: details,
										company: companyCode,
										loggedin: userLoggedIn,
										promo: promoCode,
									}));
								}
							}catch(e){
								console.error('problem in send order onClick',e);
							}
						}}
					>
						{showOrderStatusMessage(orderSendStatus, offline)}
					</button>
				</div>
			</form>
		</section>
	);
}
