import { roundToDecimalPlaces } from "./money";

type ProductForCalculation = {
	price: number;
	item_group: number;
	product_code: string;
}

export function calculateOrderTotal(
	productsInOrder: Array<{ product: ProductForCalculation; quantity: number; }>,
	discountsPercentByGroup?: Record<number, number> | null,
	customerSpecificProducts?: Record<string, Partial<ProductForCalculation>> | null,
): string {
	const total = productsInOrder.reduce((acc, item) => {
		const customerSpecificPrice = customerSpecificProducts?.[item.product.product_code]?.price;

		if(customerSpecificPrice) {
			return acc + (customerSpecificPrice * item.quantity)
		}

		if(
			discountsPercentByGroup
			&& discountsPercentByGroup[item.product.item_group] !== 0
		){
			return acc + (
				((1 - discountsPercentByGroup[item.product.item_group]))
				* item.product.price
				* item.quantity
			)
		}

		return acc + (item.product.price * item.quantity)
	}, 0);

	return roundToDecimalPlaces(total, 2, 'up').toFixed(2);
}
