import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { fetchProducts } from './productsSlice';
import { fetchDiscounts } from './userSlice';
import { toggleOffline, toggleStandalone } from './pwaSlice';
import type { RootState, AppDispatch } from '../redux/store';

const ONE_WEEK = 60000 * 60 * 24 * 7
const ONE_WEEK_AGO = Date.now() - ONE_WEEK

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useSendFetchDiscounts() {
	const discountsFetchStatus = useAppSelector(state => state.user.status);
	const loggedIn = useAppSelector(state => state.user.loggedin);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if(discountsFetchStatus === 'idle' && loggedIn){
			dispatch(fetchDiscounts());
		}
	}, [discountsFetchStatus, loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps
}

export function useSendFetchProducts() {
	const productsTimeStamp = useAppSelector(state => state.products.productsTimeStamp);
	const productFetchStatus = useAppSelector(state => state.products.status);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if(
			productFetchStatus === 'idle' ||
			productFetchStatus !== 'loading' &&
			(!productsTimeStamp ||
			productsTimeStamp < ONE_WEEK_AGO)
		){
			dispatch(fetchProducts());
		}
	}, [productFetchStatus, productsTimeStamp, ONE_WEEK_AGO]); //eslint-disable-line react-hooks/exhaustive-deps
}

export function useWebAppStatus() {
	const dispatch = useAppDispatch();
	const standaloneMatch = window.matchMedia('(display-mode: standalone)');

	//const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

	useEffect(() => {
		function onEventOnline() {
			dispatch(toggleOffline(false));
		}

		function onEventOffline() {
			dispatch(toggleOffline(true));
		}

		function standaloneStatus() {
			const isStandalone = window.matchMedia('(display-mode: standalone)').matches
				// @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
				|| ('standalone' in window.navigator && window.navigator.standalone === true);

			dispatch(toggleStandalone(isStandalone));
		}

		window.addEventListener('offline', onEventOffline);
		window.addEventListener('online', onEventOnline);
		standaloneMatch.addEventListener('change',standaloneStatus);

		return () => {
			window.removeEventListener('offline', onEventOffline);
			window.removeEventListener('online', onEventOnline);
			standaloneMatch.removeEventListener('change',standaloneStatus);
		}
	});
}
