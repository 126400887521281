import React, { Fragment, useEffect, useState } from 'react';

export default function ScrollToTop() {
	const [showScroll, setShowScroll] = useState(false)

	useEffect(() => {
		const checkScrollTop = () => {
			if(window.pageYOffset < 1200){
				setShowScroll(false)
			} else{
				setShowScroll(true)
			}
		};
		window.addEventListener('scroll', checkScrollTop);
		return () => {
			window.removeEventListener('scroll', checkScrollTop);
		}
	}, [showScroll]);

	if(!showScroll){
		return <Fragment />;
	}else{
		return (
			<button
				type="button"
                name="Scroll to top"
                title="Scroll to top"
				className="scroll-to-top"
				aria-label="Scroll to top"
				onClick={() => {
					window.scrollTo({top: 0, behavior: 'smooth'});
				}}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="">
					<path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
				</svg>
			</button>
		);
	}
}
