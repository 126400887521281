import { createSlice, createAsyncThunk,createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { getLoggedInUser } from 'business/rules';

import type { Middleware } from 'redux';
import type { AsyncStatus, CustomerDetails } from 'business/types';
import type { Promo } from 'pennyuk-business/src';

type CodeQuantity = { code: string; quantity: number; }

type OrderDetails = {
	brand: string;
	products: Array<CodeQuantity>;
	email: string;
	details: CustomerDetails;
	company: string;
	loggedin: boolean;
	promo?: string;
}

type BasketState = {
	status: 'idle' | 'sent' | 'sending' | 'failed';
	error: string | null;
	promoStatus: AsyncStatus;
	promoError: string | null;
	promo: Promo | null;
}

const basketAdapter = createEntityAdapter<CodeQuantity>({
	selectId: (product) => product.code
});

const initialState = basketAdapter.getInitialState<BasketState>({
	status: 'idle',
	error: null,
	promoStatus: 'idle',
	promoError: null,
	promo: null,
});

export function basketPreloadedState() {
	try {
		const serialisedState = window.localStorage.getItem("PukBasket");
		if(serialisedState === null){
			return initialState;
		}else{
			const basketFromLocalStorage = JSON.parse(serialisedState);
			return {
				...initialState,
				ids: basketFromLocalStorage.ids,
				entities: basketFromLocalStorage.entities
			}
		}
	} catch(e) {
		console.warn(e);
		return initialState;
	}
}

async function configureSendOrderFetch(aUserIsLoggedIn: boolean): Promise<{ endpoint: string; authToken?: string }> {
	const apiUrl = process.env.REACT_APP_API_PRICELIST_V2;
	const companyInitials = process.env.REACT_APP_COMPANY_SHORT_NAME;

	if(apiUrl === undefined) {
		throw new Error('API Address undefined.');
	}

	if(aUserIsLoggedIn && companyInitials){
		const session = await getLoggedInUser();
		const jwtToken = session.getIdToken().getJwtToken();

		return {
			endpoint: apiUrl + `/order-${companyInitials}`,
			authToken: jwtToken,
		}
	}

	return {
		endpoint: apiUrl + "/order",
	};
}

export const sendOrder = createAsyncThunk('basket/sendOrder', async (order: OrderDetails, thunkAPI) => {
	const { endpoint, authToken } = await configureSendOrderFetch(order.loggedin)

	const res = await fetch(endpoint, {
		method: 'POST',
		headers: (
			authToken !== undefined
				? {
					'Content-Type': 'application/json',
					'Authorization': authToken,
				}
				: { 'Content-Type': 'application/json' }
		),
		body: JSON.stringify(order),
	});

	if(!res.ok) throw new Error('Network response was not ok');

	console.log('Order sent.');

	return res;
});

export const fetchPromo = createAsyncThunk('basket/fetchPromo', async (promoCode: string) => {
	const stageFromDeployEnv = process.env.NODE_ENV === "development" ? "dev" : process.env.REACT_APP_DEPLOY_STAGE;

	const apiV2 = process.env.REACT_APP_API_PRICELIST_V2;

	if (!apiV2) {
		throw new Error('V2 api not set');
	}

	const response = await fetch(`${apiV2}/promo/${promoCode}`);

	console.log(`${apiV2}/promo/${promoCode}`, response.ok)

	if(!response.ok){
		throw new Error('Network response was not ok');
	}

	const contentType = response.headers.get('content-type');

	if(!contentType || !contentType.includes('application/json')){
		throw new TypeError("fetchPromo did not receive JSON");
	}

	if(stageFromDeployEnv !== 'prod'){
		console.log("fetchPromo completed");
	}

	return response.json();
});

export const basketSlice = createSlice({
	name: 'basket',
	initialState,
	reducers: {
		addToBasket: (state, action) => {
			const itemToUpdate = state.entities[action.payload.code];

			if(
				state.ids.some(item => item === action.payload.code)
				&& itemToUpdate !== undefined
			){
				basketAdapter.updateOne(
					state,
					{
						id: action.payload.code,
						changes: {
							code: action.payload.code,
							quantity: itemToUpdate.quantity + action.payload.quantity,
						}
					}
				);
			}else{
				basketAdapter.addOne(state, action.payload);
			}
		},
		removeFromBasket: (state, action) => {
			basketAdapter.removeOne(state, action.payload);
		},
		clearBasket: state => {
			basketAdapter.removeAll(state);
		},
		updateBasketQuantity: (state, action) => {
			basketAdapter.updateOne(
				state,
				{
					id: action.payload.code,
					changes: {
						quantity: action.payload.quantity
					}
				}
			);
		},
		setBasketStatusIdle: state => {
			state.status = 'idle';
		},
		removePromo: state => {
			state.promo = null;
			state.promoError = null;
			state.promoStatus = 'idle';
		}
	},
	extraReducers: (builder) => {
		builder.addCase(sendOrder.pending, (state) => {
			state.status = 'sending';
		});
		builder.addCase(sendOrder.fulfilled, state => {
			state.status = 'sent';
			if(process.env.NODE_ENV === 'production'){
				basketAdapter.removeAll(state);
			}
		});
		builder.addCase(sendOrder.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message ?? 'no error message';
		});
		builder.addCase(fetchPromo.pending, state => {
			state.promoStatus = 'loading';
		});
		builder.addCase(fetchPromo.fulfilled, (state, action) => {
			state.promoStatus = 'succeeded';

			state.promo = action.payload.promo;
		});
		builder.addCase(fetchPromo.rejected, (state, action) => {
			state.promoStatus = 'failed';
			state.promoError = action.error.message ?? 'no error message';
		});
	}
});

export const basketToLocalStorage: Middleware = (middlewareApi) => {
	let saveTimer: ReturnType<typeof setTimeout> | undefined;

	return next => action => {
		function saveDebounce() {
			if(saveTimer){
				clearTimeout(saveTimer);
			}

			saveTimer = setTimeout(() => {
				const state = middlewareApi.getState();

				const basketContents = {
					ids: state.basket.ids,
					entities: state.basket.entities
				}

				window.localStorage.setItem("PukBasket", JSON.stringify(basketContents))
			}, 1000);
		};

		saveDebounce();

		return next(action);
	};
}

export const { addToBasket, removeFromBasket, updateBasketQuantity, clearBasket, setBasketStatusIdle, removePromo } = basketSlice.actions;
export const basketReducer = basketSlice.reducer;
export const {
	selectAll: selectAllInBasket, selectTotal: basketTotal
} = basketAdapter.getSelectors<{ basket: EntityState<CodeQuantity> & BasketState}>(state => state.basket);
