export function roundToDecimalPlaces(
    numberToRound: number,
    decimalPlaces: number,
    upOrDown?: 'up' | 'down'
) {
	const power = Math.pow(10, decimalPlaces);

	if(upOrDown === 'down'){
		return Math.floor(numberToRound * power)/power;
	}
	
	if(upOrDown === 'up'){
		return Math.ceil(numberToRound * power)/power;
	}

	return Math.round(numberToRound * power)/power;
}
