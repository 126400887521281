import type { PriceListProduct } from "pennyuk-business/src";

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; //eslint-disable-line no-control-regex

export function sanitiseEmailAddress(email: string) {
	const trimmedEmail = email.trim()

	if(EMAIL_REGEX.test(trimmedEmail)){
		return email;
	}

	return escape(trimmedEmail);
}

export function parseScrewThreadFromSlug(slug: string) {
	const items = slug.split('-');

	if(!/\d{1,3}/.test(items[0])){
		return '';
	}

	if(items.length > 2){
		return `${items[0]} ${items[1]}/${items[2]}"`;
	}

	if(items.length > 1){
		return `${items[0]}/${items[1]}"`;
	}

	return `${items[0]}"`;
}

export function sanitiseCustomerDetails(detail: string) {
	var chacterWhitelist = /[^0-9A-Za-zÀ-ÖØ-öø-ÿ,μ.°\r\n\s]/g;
	return detail.replace(chacterWhitelist,'_');
}

export function checkIsNumber(n: string | number) {
	try{
		if(typeof n === 'number'){
			return n;
		}

		return parseInt(n, 10);
	}catch(e){
		console.error('error with value passed to checkIsNumber', e);
	}
}

export function sortOptions(option: 'default' | 'namedesc' | 'price' | 'pricedesc' | 'name'): (a: PriceListProduct, b: PriceListProduct) => number {
	switch(option) {
		case 'default':
			return sortCatalogueOrder
		case 'namedesc':
			return (a: { name: string }, b: { name: string }) => {
				const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();

				if(nameA > nameB){
					return -1;
				}

				if(nameA < nameB){
					return 1;
				}

				return 0;
			}
		case 'price':
			return (a: { price: number }, b: { price: number }) => a.price - b.price;
		case 'pricedesc':
			return (a: { price: number }, b: { price: number }) => b.price - a.price;
		case 'name':
		default:
			return (a: { name: string }, b: { name: string }) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();

				if(nameA < nameB){
					return -1;
				}

				if(nameA > nameB){
					return 1;
				}

				return 0;
			}
	}
}

export function compareSearchTextWithProduct(
	text: string,
	product: { product_code: string; name: string; meta?: { tags?: string[] } },
	mode: "any" | "all"
) {
	if(text.includes(product.product_code.toLowerCase())){
		return true;
	}

	const listOfTerms = text.split(/[\s-]+/);

	const productNameParts = product.name.toLowerCase().split(/[\s-]+/);

	const productTextToSearch = product.meta && Array.isArray(product.meta.tags)
		? productNameParts.concat(product?.meta?.tags)
		: productNameParts

	if(mode === "any"){/*match any term*/
		return listOfTerms.some(term => productTextToSearch.includes(term));
	}

	/* match all terms*/
	return listOfTerms.every(term => productTextToSearch.includes(term));
}

export function sortCatalogueOrder(a: PriceListProduct, b: PriceListProduct) {
	if (a.sort && b.sort && a.item_group && b.item_group) {
		return (a.item_group * 1000 + a.sort) - (b.item_group * 1000 + b.sort)
	}

	return a.item_group && b.item_group
		? a.item_group - b.item_group : 0
}
