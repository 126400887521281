import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
	name: 'search',
	initialState: {
		text: '',
		mode: 'all',
		clear: false
	},
	reducers: {
		changeText: (state, action) => {
			state.text = action.payload;
		},
		changeMode: (state, action) => {
			state.mode = action.payload;
		},
		toggleClear: (state, action) => {
			state.clear = action.payload;
		}
	}
});

export const { changeText, changeMode, toggleClear } = searchSlice.actions;
export default searchSlice.reducer;
