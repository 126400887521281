import React from 'react';

export default function NavBurger({open, setOpen}) {

	const active = (open ? " active" : "");

	return(
		<button
			tabIndex="0"
			type="button"
			name="Toggle main menu"
			className={"burger" + active}
			aria-label="Toggle main menu"
			aria-controls="main-menu"
			aria-expanded={open}
			open={open}
			onClick={() => {
				setOpen(!open);
			}}
		>
			<span className={active} />
			<span className={active} />
			<span className={active} />
	  </button>
	);
}
