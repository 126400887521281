import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAllProductInfoExpanded } from '../../redux/userSlice';

export default function LayoutControls({ productControls, setProductControls, showCategories, setShowCategories }) {
	const dispatch = useDispatch();
	const allProductInfoExpanded = useSelector(state => state.user.productInfoExpanded);
	const [showControls,setShowControls] = useState(false);
	return(<section className="layout-controls">
		<button
			role="switch"
			type="button"
			className="lightblue-rounded-button"
			aria-checked={showControls}
			onClick={() => {setShowControls(!showControls);}}
		>
			<h2>Layout Controls</h2>
		</button>
		{!showControls ? <Fragment /> : <ul className="layout-controls-menu">
			<li>
				<button
					role="switch"
					type="button"
					aria-checked={productControls}
					aria-labelledby="toggle-product-controls-label"
					onClick={() => {setProductControls(!productControls);}}
				>
					<span className="on">on</span>
					<span className="off">off</span>
				</button>
				<span id="toggle-product-controls-label" className="toggle-switch-label">Product Controls</span>
			</li>
			<li>
				<button
					role="switch"
					type="button"
					aria-checked={showCategories}
					aria-labelledby="toggle-category-menu-label"
					disabled={!productControls}
					onClick={() => {setShowCategories(!showCategories);}}
				>
					<span className="on">on</span>
					<span className="off">off</span>
				</button>
				<span id="toggle-category-menu-label" className="toggle-switch-label">Category Menu</span>
			</li>
			<li>
				<button
					role="switch"
					type="button"
					aria-checked={allProductInfoExpanded}
					aria-labelledby="toggle-product-expand-label"
					onClick={() => {
						dispatch(toggleAllProductInfoExpanded(!allProductInfoExpanded));
						window.localStorage.setItem("PukProductInfoExpanded", !allProductInfoExpanded);
					}}
				>
					<span className="on">on</span>
					<span className="off">off</span>
				</button>
				<span id="toggle-product-expand-label" className="toggle-switch-label">Expand All Products</span>
			</li>
		</ul>}
	</section>);
}
