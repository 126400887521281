import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

export default function TrafficLight({colour}){
	const a11yMode = useSelector(state => state.user.a11y)

	if(colour === "AMBER"){
		return(<Fragment>
			<div
				className="traffic-light amber"
				data-light={colour}
				title="Product may not be available.  Please contact us."
			>
			</div>
			<small>{a11yMode ? "AMBER" : ""}</small>
		</Fragment>)
	}else{
		return(<Fragment>
			<div
				className="traffic-light green"
				data-light={colour}
				title="Product is in stock."
			>
			</div>
			<small>{a11yMode ? "GREEN" : ""}</small>
		</Fragment>)
	}
}
