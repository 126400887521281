import { categoryData, parentSlugsByGroup, slugsByGroup } from 'business/categoryData';
import ProductItemPicture from 'components/product/ProductItemPicture';
import type { PriceListProduct } from 'pennyuk-business/src';

export default function ProductItemExpanded({ item }: { item: PriceListProduct }) {
	const stageFromDeployEnv = process.env.REACT_APP_DEPLOY_STAGE;

	if(!item.info){
		return(
			<div className="product-row-expanded">
				<div className="item-expand-pic"></div>
				<div className="item-expand-description">
					<p className={"cat cat-colour-" + parentSlugsByGroup[item.item_group]}><small>
						{categoryData[slugsByGroup[item.item_group]]?.name || "-"}
					</small></p>
				</div>
			</div>
		);
	}

	return(
		<div className="product-row-expanded">
			<div className="item-expand-pic">
				<ProductItemPicture item={item} />
			</div>
			<div className="item-expand-description">
				<p className={"cat cat-colour-" + parentSlugsByGroup[item.item_group]}><small>
					{categoryData[slugsByGroup[item.item_group]]?.name || "-"}
				</small></p>
				<ProductItemInfoParagraph name="ordering" info={item.info} />
				<ProductItemInfoParagraph name="describe1" info={item.info} />
				<ProductItemInfoBullets code={item.product_code} info={item.info} />
				<ProductItemInfoParagraph name="describe2" info={item.info} />
				<ProductItemInfoLinks code={item.product_code} info={item.info} />
				<small>{stageFromDeployEnv !== 'prod' && !!item.meta?.tags && item.meta?.tags?.length > 0 ? "Dev Tags:" + item.meta.tags.join() : ""}</small>
			</div>
		</div>
	);
}

type ProductItemInfoParagraphProps = {
	info: PriceListProduct['info'];
	name: 'ordering' | 'describe1' | 'describe2';
}

function ProductItemInfoParagraph({ info, name }: ProductItemInfoParagraphProps) {
	const infoItem = info[name];

	if(!infoItem || infoItem.trim().length === 0){
		return null;
	}

	return(
		<p className={"information-"+name}>{infoItem}</p>
	);
}

type CodeAndInfoProps = {
	code: string;
	info: PriceListProduct['info'];
}

function ProductItemInfoBullets({ code, info }: CodeAndInfoProps) {
	if(!info.bullets || info.bullets.length === 0){
		return null;
	}

	return(
		<ul>
			{info.bullets.map((bullet, i) => <li key={code+'b'+i}>{bullet}</li>)}
		</ul>
	);
}

function ProductItemInfoLinks({ code, info }: CodeAndInfoProps) {
	const companyDomain = process.env.REACT_APP_COMPANY_DOMAIN ?? '';

	if(!info.links || info.links.length === 0){
		return null;
	}

	return(
		<ul className="links">
			{info.links.map((link, i) => {
				const companyLink = replaceDomain(link.href, companyDomain);

				const companyLinkName = replaceDomain(link.name, companyDomain);

				return(
					<li key={code+'l'+i}>
						<a href={companyLink} target="_blank" rel="noopener noreferrer">{companyLinkName}</a>
					</li>
				);
			})}
		</ul>
	);
}

function replaceDomain(text: string, domain: string) {
	return text.replace(/(pennyukltd.com)|(%COMPANY_DOMAIN%)/g, domain);
}
