import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toggleA11y } from '../redux/userSlice';
import Header from './Header';
import PwaStatus from './pwa/Status';

const stageFromBuildEnv = process.env.NODE_ENV;
const stageFromDeployEnv = process.env.NODE_ENV === "development" ? "dev" : process.env.REACT_APP_DEPLOY_STAGE;

export default function Help() {
	const a11yMode = useSelector(state => state.user.a11y);
	const dispatch = useDispatch();

	return(<div id='help' className={'nav-page' + (a11yMode ? ' a11y' : '')}>
		<Header title={"Help"} />
		<aside>
			<h4>Contents</h4>
			<ul>
				<li><a href="#a11y">Accessibility</a></li>
				<li><a href="#user-account">User Accounts</a></li>
				<li><a href="#links">Links</a></li>
				<li><a href="#search">Search</a></li>
				<li><a href="#pwa">Progressive Web App</a></li>
				<li><a href="#misc">Misc</a></li>
			</ul>
		</aside>
		<main>
			<ul className="help-sections">
				<li id="a11y">
					<h4>Accessibility</h4>
					<dl>
						<dt><i>I'm colour blind and can't read the stock indicators.</i></dt>
							<dd>Please activate Accessibility Mode below.</dd>
					</dl>
					<button
						role="switch"
						aria-checked={a11yMode}
						type="button"
						name={"Toggle Accessibility Mode"}
						title={"Toggle Accessibility Mode"}
						className="lightblue-rounded-button prominent my-3"
						onClick={() => {
							dispatch(toggleA11y(!a11yMode));
							window.localStorage.setItem("PukA11y", !a11yMode);
						}}
					>
						{a11yMode ? "Deactivate" : "Activate"} Accessibility Mode
					</button>
				</li>
				<li id="user-account">
					<h4>User Accounts</h4>
					<dl>
						<dt><i>How do I register?</i></dt>
						<dd>We issue logins to our existing customers.<br />
						If you wish to become a customer please <Link to="/contact" className="contact-link">contact us</Link>.<br />
						</dd>
						<dt><i>I've forgotten my password</i></dt>
						<dd>Please go to <Link to="/user-account" className="user-account">User Account</Link>, enter your email and click "Forgotten password".<br />
						A reset code will be sent to your email address.
						You can then click "Enter a password reset code" and change your password.</dd>
					</dl>
				</li>
				<li id="links">
					<h4>Links</h4>
					<dl>
						<dt><i>How do I link to a specific product?</i></dt>
						<dd>You can use <samp>/code/[Rxxxx]</samp> in the browser address bar like this:<br />
						<Link to="/code/RA5771" className="help-link">{process.env.PUBLIC_URL + "/code/RA5771"}</Link><br />
						</dd>

						<dt><i>How do I link to a product group?</i></dt>
						<dd>You can use <samp>/group/[number]</samp> in the browser address bar like this:<br />
						<Link to="/group/160" className="help-link">{process.env.PUBLIC_URL + "/group/160"}</Link><br />
						<b>Also;</b> The category and group links from the previous ecommerce website have been included so your old bookmarks will still work.
						</dd>

						<dt><i>Are there any other links?</i></dt>
						<dd>Yes. Try these:
						<ul>
							<li><Link to="/valve-size/1-1-4/" className="help-link">/valve-size/1-1-4/</Link></li>
							<li><Link to="/pipe-diameter/25mm/" className="help-link">/pipe-diameter/25mm/</Link></li>
							<li><Link to="/screw-thread/2/" className="help-link">/screw-thread/2/</Link></li>
						</ul>

						More routes may be added in future.  If you have feedback on anthing you'd like to see, please let us know.
						</dd>
					</dl>
				</li>
				<li id="search">
					<h4>Search</h4>
					<dl>
						<dt><i>How do I search for 45° only?</i></dt>
							<dd>The search will accept 'deg' and 'degrees' with a space.  Type "45 deg" or "45 degrees" into the search.</dd>
						<dt><i>How do I search by filtration degree?</i></dt>
							<dd>Use the word 'micron'.  For example: "90 micron" in the search for 90μm.</dd>
					</dl>
				</li>
				<li id="pwa">
					<h4>Progressive Web App</h4>
					<dt><i>What is a Progressive Web App?</i></dt>
					<dd>A <a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="_blank" rel="noopener noreferrer">Progressive Web Application</a> or <i>PWA</i> is a website with the ability to work offline (after the first visit) and to be installed on a device.</dd>
					<dt><i>How do I install?</i></dt>
					<dd>Installing uses your web browser and varies depending on the browser and device.
						<dl>
							<dt>iPhone and iPad</dt>
							<dd>Open the website in Safari. Tap the 'Share' button. Scroll down and tap 'Add to Home Screen'.</dd>
							<dt>Android</dt>
							<dd>Chrome will show a notification banner at the bottom of the screen.  To maually install; tap the 3 dots at the top right and tap 'Add to Home screen' or 'Install app'.</dd>
							<dt>Desktop - Chrome (since version 76) <small>(including Chrome based browsers such as Microsoft Edge)</small></dt>
							<dd>You will see a plus in a circle on the end of your address bar - <InstallSymbol /><br />
							Clicking this followed by the prompt to install will add a shortcut to your Windows Start menu, macOS Launchpad or Chromebook Launcher.</dd>
						</dl>
					</dd>
					<dl><i>Do I need to install for the web app to work offline?</i></dl>
					<dd>No. Try turning on airplane mode and refreshing the page.  If your browser is compatible, you will be able to continue to use the web app.</dd>
					<PwaStatus />
				</li>
				<li id="misc">
					<h4>Misc</h4>
					<dl>
						<dt><i>How often is product information updated?</i></dt>
						<dd>Continuously.  The web app receives data from our enterprise resource planning (ERP) software.<br />
						<ul>
							<li>Stock updates are processed immediately after they are updated in the office.</li>
							<li>Prices and discounts come in to effect the morning after they are changed in the office.</li>
						</ul>
						You will need to refresh your browser to get the latest data.</dd>
						<dt><i>What is the current version of the web app?</i></dt>
						<dd
							data-deploy-stage={stageFromDeployEnv}
							data-build-stage={stageFromBuildEnv}
						>
							{process.env.REACT_APP_VERSION}
						</dd>
					</dl>
				</li>
			</ul>
		</main>
	</div>);
}

function InstallSymbol() {
	return(<svg className="svg-plus-circle" viewBox="0 0 100 100" strokeWidth="5">
		<circle cx="50" cy="50" r="40" stroke="black" fill="none" />
		<line x1="50" y1="20" x2="50" y2="80" stroke="black" />
		<line x1="20" y1="50" x2="80" y2="50" stroke="black" />
	</svg>);
}
