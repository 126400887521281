import React, { useState } from "react";
import { useSelector } from 'react-redux';

import { forgotPasswordSubmit, matchValidPassword } from '../../business/rules';

import { UserInputPassword } from './UserInputs';

export default function UserResetCodeEntry({validEmail}) {
	const email = useSelector(state => state.user.email);
	const [newPassword, setNewPassword] = useState("");
	const [changeCode, setChangeCode] = useState("");

	function handlePasswordChange(event) {
		event.preventDefault();
		forgotPasswordSubmit(email, changeCode, newPassword,
			() => {
				window.location.reload();
			}
		)
	}

	function validateChangeForm() {
		var valid = matchValidPassword(newPassword);
		return newPassword.length > 0 && changeCode.length > 0 && valid;
	}

	if(validEmail){
		return(<div>
			<p>If you have already received a verification code, please use it to change your password below.</p>
			<form onSubmit={handlePasswordChange}>
				<div className="form-group">
					<label className="form-label" htmlFor={"formResetCode"}>Reset Code</label>
					<input
					id={"formResetCode"}
					type="password"
					value={changeCode}
					onChange={e => setChangeCode(e.target.value)}
					/>
				</div>
				<UserInputPassword
					isNew={true}
					password={newPassword}
					setPassword={setNewPassword}
				/>
				<button
					className='account-button lightblue-rounded-button'
					disabled={!validateChangeForm()}
					type="submit">
					Set New Password
				</button>
				<p>After setting your password, please use it to login.</p>
			</form>
		</div>);
	}else{
		return(<div><p>Please enter your email in the login field above.</p></div>);
	}
}
