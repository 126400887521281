import { useState } from 'react';
import { useAppDispatch } from 'redux/customHooks';
import { addToBasket } from 'redux/basketSlice';
import QuantityControl from 'components/controls/QuantityControl';
import type { AppDispatch } from 'redux/store';
import type { PriceListProduct } from 'pennyuk-business/src';

function validateAndDispatch(
	quantity: number,
	item: PriceListProduct,
	setMessage: ((value: React.SetStateAction<string>) => void),
	dispatch: AppDispatch,
) {
	const unit = (item.info.unit === 'metre' ? ' metres' : '');

	if(quantity < 1){
		setMessage("Can't add less than 1");
	}else{
		const multiple = !item?.batch ? 1 : item.batch;

		if(quantity % multiple === 0){
			dispatch(addToBasket({ code: item.product_code, quantity }));
			setMessage(quantity + unit + ' added');
		}else{
			setMessage('Must be multiple of ' + multiple);
		}
	}
}

type AddToOrderControlProps = {
	item: PriceListProduct;
	quantity: number;
	setQuantity: (value: React.SetStateAction<number>) => void;
}

export default function AddToOrderControl({item, quantity, setQuantity}: AddToOrderControlProps) {
	const [message, setMessage] = useState('');
	const dispatch = useAppDispatch();

	return (
		<div className="ordering-controls">
			<QuantityControl
				code={item.product_code}
				unit={item.info.unit}
				quantity={quantity}
				setQuantity={setQuantity}
				message={message}
			/>
			<button
				type="button"
				name="add to order"
				title="add to order"
				className="add-to-order lightblue-rounded-button"
				onClick={() => validateAndDispatch(quantity, item, setMessage, dispatch)}
			>
				<span className="add-to-order-text">Add&nbsp;To Order</span>
			</button>
		</div>
	);
}
