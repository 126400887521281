import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { signIn } from '../../business/rules';

import { toggleLoggedIn, toggleNewPassNeeded } from '../../redux/userSlice';
import { UserInputEmail, UserInputPassword } from './UserInputs';
import UserNewPasswordNeeded from './UserNewPasswordNeeded';
import UserForgotPassword from './UserForgotPassword';
import UserResetCodeEntry from './UserResetCodeEntry';

export default function UserLogin() {
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [showUserResetCodeEntry,setShowUserResetCodeEntry] = useState(false);
	const email = useSelector(state => state.user.email);
	const newPassNeeded = useSelector(state => state.user.newPassNeeded);
	const [passResetSent,setPassResetSent] = useState(false);
	const [password, setPassword] = useState("");
	const [validEmail, setValidEmail] = useState(false);
	const dispatch = useDispatch();

	function validateForm() {
		return email.length > 0 && password.length > 0;
	}

	function handleSubmit(event) {
		event.preventDefault();
		signIn(
			email,
			password,
			toggle => {dispatch(toggleNewPassNeeded(toggle))},
			toggle => {
				dispatch(toggleLoggedIn(toggle));
				window.location.reload();
			}
		);
	}

	useEffect(() => {
		setValidEmail(/\S+@\S+\.\S{2}/.test(email));
	},[email])

	return(<div className="login">
		<form onSubmit={handleSubmit}>
			<UserInputEmail />
			<UserInputPassword isNew={false} password={password} setPassword={setPassword} />
			{(newPassNeeded ? <p className="instruction-to-enter-new-password">Please scroll down and enter a new password.</p> : <Fragment />)}
			<button className='account-button lightblue-rounded-button' disabled={!validateForm() || newPassNeeded} type="submit">
				Login
			</button>
		</form>
		{newPassNeeded ? <UserNewPasswordNeeded oldPassword={password} /> : ''}
		<p><small>{validEmail ? "Click below for Forgotten Password and Reset Code entry" : "Please enter an email address"}</small></p>
		<hr />
		<button
			type="button"
			role="switch"
			aria-checked={showForgotPassword}
			disabled={!validEmail}
			title={email.length > 0 ? "Click to show Forgotten Password form" : "Please enter an email address"}
			className='account-button lightblue-rounded-button'
			onClick={() => setShowForgotPassword(!showForgotPassword)}>
				Forgotten password
		</button>
		{(				
			showForgotPassword ?
			<UserForgotPassword
				validEmail={validEmail}
				passResetSent={passResetSent}
				setPassResetSent={setPassResetSent}
			/>
			: ''
		)}
		<hr />
		<button
			type="button"
			role="switch"
			aria-checked={showUserResetCodeEntry}
			disabled={!validEmail}
			title={email.length > 0 ? "Click to show Reset Code form" : "Please enter an email address"}
			className='account-button lightblue-rounded-button'
			onClick={() => {setShowUserResetCodeEntry(!showUserResetCodeEntry)}}>
				Enter a password reset code
		</button>
		{showUserResetCodeEntry? <UserResetCodeEntry validEmail={validEmail} /> : ''}
	</div>);
}
