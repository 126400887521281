import React from "react";
import { useSelector } from 'react-redux';

import { forgotPassword } from '../../business/rules';

export default function UserForgotPassword({validEmail, passResetSent, setPassResetSent}) {
	const email = useSelector(state => state.user.email);

	function handleReset(event) {
		event.preventDefault();
		setPassResetSent(true);
		forgotPassword(email);
	}

	if(validEmail){
		return(<div>
			<p><i>A temporary password cannot be reset</i><br />
			<i>If you have not changed the password first emailed to you, please enter it above and click login.</i><br />
			<i>Please contact us if you are having difficulty.</i></p>
			<p>Clicking <i>Reset Password</i>, will send a verification code to <b>{email}</b></p>
			<form onSubmit={handleReset}>
				<button
					className='account-button lightblue-rounded-button'
					disabled={(passResetSent || !email || email.trim().length === 0)} type="submit">
						Reset Password
				</button>
			</form>
			<p>When you have received your code please return here and click <i>Enter a password verification code</i> below.</p>
		</div>);
	}else{
		return(<div><p>Please enter your email in the login field above.</p></div>);
	}
}
