import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeMode } from '../../redux/searchSlice';

export default function SearchMode() {
	const dispatch = useDispatch();
	const [isAny, setIsAny] = useState(false);

	useEffect(() => {
		if(!isAny) {
			dispatch(changeMode("all"));
		}else{
			dispatch(changeMode("any"));
		}
	},[isAny, setIsAny]); // eslint-disable-line

	return(<fieldset className="search-mode-control">
		<legend><small>Select search mode</small></legend>
		<div>
			<input
				type="radio"
				id="search-all-terms"
				name="search-mode"
				value="all"
				className={!isAny ? 'checked' : ''}
				defaultChecked={!isAny}
				onChange={() => {setIsAny(false);}}
			/>
			<label htmlFor="search-all-terms">Match all words</label>
		</div>
		<div>
			<input
				type="radio"
				id="search-any-term"
				name="search-mode"
				value="any"
				className={isAny ? 'checked' : ''}
				defaultChecked={isAny}
				onChange={() => {setIsAny(true);}}
			/>
			<label htmlFor="search-any-term">Match any word</label>
		</div>
		{/*<div>
			<input
				type="checkbox"
				id="search-clear-terms"
				name="search-clear"
				className='search-clear'
				checked={clear}
				onChange={() => {
					dispatch(toggleClear(!clear))
				}}
			/>
			<label htmlFor="search-clear-terms"><small>Clear search on category change</small></label>
		</div>*/}
	</fieldset>);
}
