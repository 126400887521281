import { roundToDecimalPlaces } from './money';
import { calculateOrderTotal } from './orders';
import { getSubTotalWithPromo, getOrderTotalWithPromoApplied } from './promos';

import type { DbProductWithPrice, PriceListProduct, CodeWithQuantity, ProductWithQuantityAndPrices } from './types/products';
import type { Promo } from './types/promo';

export function getOrderProductsAndTotal<P extends DbProductWithPrice | PriceListProduct>(
    productIds: Array<string>,
    productData: Record<string, P>,
    userProductData: Record<string, Partial<P>> | null,
    discountsData: Record<number, number> | null,
    itemsInBasket: Array<CodeWithQuantity>,
    promo: Promo | null,
) {
    const isDiscountsEmpty = !discountsData || Object.keys(discountsData).length === 0;

    const basketProducts: Array<ProductWithQuantityAndPrices<P>> = [];

    for (const basketItem of itemsInBasket) {
        if(!productIds.includes(basketItem.code)) {
            continue;
        }

        const item: P | undefined = productData[basketItem.code];

        if (!item) {
            continue;
        }

        if(userProductData && basketItem.code in userProductData) {
            const userProduct = userProductData[basketItem.code];

            if(!userProduct.price) {
                continue;
            }

            basketProducts.push({
                product: {
                    ...item,
                    ...userProduct,
                    price: item.price,
                },
                priceDiscounted: userProduct.price,
                subTotal: roundToDecimalPlaces(userProduct.price * basketItem.quantity, 2),
                subTotalWithPromo: (
                    promo
                        ? getSubTotalWithPromo(
                            promo,
                            {
                                product_code: item.product_code,
                                price: userProduct.price,
                                item_group: item.item_group
                            },
                            basketItem.quantity,
                            2
                        )
                        : undefined
                ),
                quantity: basketItem.quantity
            });

            continue;
        }

        if(!isDiscountsEmpty && discountsData[item.item_group]) {
            const discount = discountsData[item.item_group];

            const subTotal = roundToDecimalPlaces(item.price * basketItem.quantity * (1 - discount), 2);

            basketProducts.push({
                product: item,
                quantity: basketItem.quantity,
                priceDiscounted: roundToDecimalPlaces(item.price * (1 - discount), 4),
                subTotal,
                subTotalWithPromo: (
                    promo
                        ? getSubTotalWithPromo(
                            promo,
                            {
                                product_code: item.product_code,
                                price: item.price * (1 - discount),
                                item_group: item.item_group
                            },
                            basketItem.quantity,
                            2
                        )
                        : undefined
                ),
            });

            continue;
        }

        basketProducts.push({
            product: item,
            quantity: basketItem.quantity,
            subTotal: roundToDecimalPlaces(item.price * basketItem.quantity, 2),
            subTotalWithPromo: (
                promo
                    ? getSubTotalWithPromo(promo, item, basketItem.quantity, 2)
                    : undefined
            ),
        });
    }

    const basketTotalCost = calculateOrderTotal(
        basketProducts,
        discountsData,
        userProductData,
    );

    if(!promo || Date.now() < promo.start || Date.now() > promo.end) {
        return { basketProducts, basketTotalCost };
    }

    const basketTotalCostWithPromoApplied = getOrderTotalWithPromoApplied(
        basketProducts,
        promo,
        discountsData,
        userProductData,
    );

    return { basketProducts, basketTotalCost, basketTotalCostWithPromoApplied };
}
