import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import UserLogoutButton from './user-account/UserLogoutButton';
import NavBurger from './NavBurger';
import { basketTotal } from '../redux/basketSlice';

const companyWebAddress = process.env.REACT_APP_COMPANY_DOMAIN;
const companyAltName = process.env.REACT_APP_COMPANY_ALT_NAME;

export default function NavMainMenu() {
	const loggedIn = useSelector(state => state.user.loggedin);
	const numberInBasket = useSelector(basketTotal);
	const [navOpen, setNavOpen] = useState(false);
	const isAdmin = useSelector(state => state.user.access.admin);

	const isStandalone = useSelector(state => state.pwa.standalone);

	useCloseNavEvents(setNavOpen);

	const location = useLocation()
	const path = location.pathname;
	useEffect(() => {
		document.title = companyAltName + " Price List " + path.substring(1);
	},[path]);

	return(<Fragment>
	<nav className={"menu slide-from-left" + (navOpen ? " open" : "")}>
		<ul className={(navOpen ? "open" : "")}>
			<li><Link to="/" className="menu-link" tabIndex="1">Price List</Link></li>
			<li><Link to="/basket" className="menu-link" tabIndex="2">
				Basket <small>{numberInBasket > 0 ? "("+numberInBasket+")" : ""}</small>
			</Link></li>
			<li><Link to="/user-account" className="menu-link" tabIndex="3">User Account</Link></li>
			{!isAdmin ? <Fragment /> : <li><Link to="/admin" className="menu-link" tabIndex="4">Admin</Link></li>}
			{!isStandalone ? <li><a href={`https://www.${companyWebAddress}`} target="_blank" rel="noopener noreferrer" className="menu-link puk-dot-com" tabIndex="5">{companyWebAddress}</a></li> : <Fragment />}
			{loggedIn ? <li><UserLogoutButton /></li> : ''}
		</ul>
		<NavBurger open={navOpen} setOpen={setNavOpen} />
	</nav>
	{ navOpen ? <button
		className="nav-overlay" onClick={() => {
			setNavOpen(false);

		}}></button> : ""}
	</Fragment>);
}

function useCloseNavEvents(setOpen) {
	useEffect(() => {
		const closeNav = () => {
			setOpen(false);
		};
		window.addEventListener('resize', closeNav);
		window.addEventListener('scroll', closeNav);
		return () => {
			window.removeEventListener('resize', closeNav);
			window.removeEventListener('scroll', closeNav);
		}
	}, [setOpen]);
}
