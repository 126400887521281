import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { signInOnPasswordChange } from '../../business/rules';

import { toggleLoggedIn } from '../../redux/userSlice';
import { UserInputPassword } from './UserInputs';

export default function UserNewPasswordNeeded({oldPassword}) {
	const email = useSelector(state => state.user.email);
	const [newPassword, setNewPassword] = useState("");
	const dispatch = useDispatch();

	function validateForm() {
		return newPassword.length > 0 && oldPassword.length > 0;
	}

	function handleSubmit(event) {
		event.preventDefault();
		signInOnPasswordChange(email, oldPassword, newPassword, (loggedin) => {
			dispatch(toggleLoggedIn(loggedin))
		});
	}

	return(<div>
		<p><b>You must set a new password.</b></p>
		<form onSubmit={handleSubmit}>
			<UserInputPassword isNew={true} password={newPassword} setPassword={setNewPassword} />
			<button className='account-button lightblue-rounded-button' disabled={!validateForm()} type="submit">Set New Password and Login</button>
		</form>
	</div>);
}
