const PRICES_CONFIG = {
	decimalPlacesDisplay: {
		clientBasket: {
			price: 4,
			subtotal: 2,
			total: 2,
		}
	}
};

export function calculateAndFormatPriceForCsv(price: number, itemGroup: number, discountData: Record<number, number> | null) {
	if(!discountData){
		return price;
	}

	const calcResult = price * (!discountData[itemGroup] ? 1 : 1 - discountData[itemGroup]);

	return roundToDecimalPlaces(calcResult, 4);
}

function roundToDecimalPlaces(numberToRound: number, decimalPlaces: number, upOrDown?: 'up' | 'down') {
	const power = Math.pow(10, decimalPlaces);

	if(upOrDown === 'down'){
		return Math.floor(numberToRound * power)/power;
	}
	
	if(upOrDown === 'up'){
		return Math.ceil(numberToRound * power)/power;
	}

	return Math.round(numberToRound * power)/power;
}

export function calculateRoughSubTotal(price: number, quantity: number) {
	return roundToDecimalPlaces(
		price * quantity,
		PRICES_CONFIG.decimalPlacesDisplay.clientBasket.subtotal);
}

export function formatPriceWithTrailingZeros(price: number) {
	const priceUnformatted = price.toString();
	const [pounds, pennies] = priceUnformatted.split('.');
	const pence = formatPennies(pennies);

	return "£" + pounds + "." + pence;
}

function formatPennies(pennies: string) {
	if(!pennies){
		return "00";
	}

	if(pennies.length === 1){
		return `${pennies[0]}0`;
	}

	return pennies;
}
