import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Agreement({name, onAgree, link}) {
	const [agree, setAgree] = useState(false);

	useEffect(() => {
		if(agree === true){
			onAgree()
		}
	},[agree, onAgree])

	return (<section>
		<p><b>Please agree to {name}</b><br />By pressing 'I agree' you are confirming you have read and understood the information here:<br /><Link to={link} className="agree-link">{name}</Link></p>
		<button
			type="button"
			name={"Agree to "+name}
			title={"Agree to "+name}
			className="lightblue-rounded-button prominent"
			onClick={() => {
				setAgree(true);
			}}
		>
			I Agree
		</button>
	</section>);
};
