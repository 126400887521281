import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

export default function PwaStatus() {
	const pwaInit = useSelector(state => state.pwa.initialized);
	const pwaStandalone = useSelector(state => state.pwa.standalone);
	const isUpdated = useSelector(state => state.pwa.update);

	if(!pwaInit && !pwaStandalone && !isUpdated){
		return <Fragment />
	}else{
		return(<div title="PWA Status">
			<h5>PWA Status:</h5>
			<ul>
			{!pwaStandalone ? <Fragment /> : <li>App running in standalone mode</li>}
			{!pwaInit ? <Fragment /> : <li>Preparation for offline use completed</li>}
			{!isUpdated ? <Fragment /> : <li>A PWA update is available but has not been applied.  Please close the app in all open tabs and windows and then reopen.</li>}
			</ul>
		</div>);
	}
}
