import { createSelector } from "@reduxjs/toolkit";
import { getOrderProductsAndTotal } from 'pennyuk-business/src';
import type { PriceListProduct, Promo, ProductWithQuantityAndPrices } from 'pennyuk-business/src';

import { selectProductCodes } from "redux/productsSlice";
import { selectAllInBasket } from "redux/basketSlice";
import type { RootState } from 'redux/store';

type ProductWithQuantityList = Array<ProductWithQuantityAndPrices<PriceListProduct>>;

type Result = {
    basketProducts: ProductWithQuantityList;
    basketTotalCost: string;
    basketTotalCostWithPromoApplied?: string;
}

export const selectBasketProductsAndTotal = createSelector<
    Array<(state: RootState) => any>,
    Result
>(
    [
        selectProductCodes,
        state => state.products.entities,
        state => state.user.products,
        state => state.user.discounts,
        selectAllInBasket,
        state => state.basket.promo,
    ],
    (
        productIds,
        productData: Record<string, PriceListProduct>,
        userProductData: Record<string, Partial<PriceListProduct>> | null,
        discountsData: Record<number, number> | null,
        itemsInBasket,
        promo: Promo | null,
    ) => getOrderProductsAndTotal(
            productIds,
            productData,
            userProductData,
            discountsData,
            itemsInBasket,
            promo,
    )
);
