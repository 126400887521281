import React from 'react';
import { logout } from '../../business/rules';

export default function UserLogoutButton() {
	return(
		<button 
			className="menu-link"
			onClick={() => {
				logout()
			}}
		>
			Logout
		</button>
	);
}
