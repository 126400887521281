import { useMemo } from 'react';
import { useRouterCategories, useRouterCategoryHistory } from 'redux/customRouterHooks';
import { changeSlug, selectAllProducts } from 'redux/productsSlice';
import { categoryData, parentSlugList, ParentSlugs, ChildSlugs, Slugs } from 'business/categoryData';
import { useAppDispatch, useAppSelector } from 'redux/customHooks';

export default function CategoriesMenu({ hide }: { hide: boolean }) {

	useRouterCategories();

	const parentCategories = useMemo(() => {
		return parentSlugList.map(item => (
			<ProductParentCategory
				key={categoryData[item].id}
				parentCat={item}
				layout={hide}
			/>
		))
	}, [hide]);

	return(
		<div className='menu-product-categories'>
			{parentCategories}
		</div>
	);
}

function ProductParentCategory({ parentCat, layout }: { parentCat: ParentSlugs, layout: boolean }) {
	const productFetchStatus = useAppSelector(state => state.products.status);
	const currentCategory = useAppSelector(state => state.products.category);
	const childCategoriesOfParent = categoryData[parentCat].children ?? [];
	const categorySlugs = [parentCat, ...childCategoriesOfParent];

	if(currentCategory !== '/' && categorySlugs.includes(currentCategory)) {
		return(
			<>
				<ProductCategoryButton
					cat={parentCat}
					active={parentCat === currentCategory}
					parent={parentCat}
					name={categoryData[parentCat].name}
					layout={layout}
				/>
				{(productFetchStatus === "succeeded" &&
					<CategoriesMenuChildren
						childSlugs={childCategoriesOfParent}
						currentCategory={currentCategory}
						parentCat={parentCat}
						layout={layout} 
					/>
				)}
			</>
		);
	}else{
		return(<ProductCategoryButton
			cat={parentCat}
			active={false}
			parent={parentCat}
			name={categoryData[parentCat].name}
			layout={layout}
		/>);
	}
}

type CategoriesMenuChildrenProps = {
	childSlugs: Array<ChildSlugs>;
	currentCategory: Slugs;
	parentCat: ParentSlugs;
	layout: boolean;
}

function CategoriesMenuChildren({ childSlugs, currentCategory, parentCat, layout }: CategoriesMenuChildrenProps) {
	const products = useAppSelector(selectAllProducts);

	const childSlugsWithProducts = useMemo(() => {
		return childSlugs.filter(slug => 
			products.some(
				product => product.item_group === categoryData[slug].item_groups[0]
			)
		);
	}, [childSlugs,products]);

	const childButtons = childSlugsWithProducts.map(slug => {
		return(<ProductCategoryButton
			key={categoryData[slug].id}
			cat={slug}
			active={slug === currentCategory}
			parent={parentCat}
			name={categoryData[slug].name}
			layout={layout}
		/>);
	});

	return(<>
		{childButtons}
	</>);
}

type ProductCategoryButtonProps = {
	cat: Slugs;
	active: boolean;
	parent: ParentSlugs;
	name: string;
	layout: boolean;	
}

function ProductCategoryButton({cat, active, parent, name, layout}: ProductCategoryButtonProps) {
	const dispatch = useAppDispatch();

	const updateHistory = useRouterCategoryHistory();

	return <button
		type="button"
		name={"select " + name + " button"}
		title={name + (cat === parent ? " parent category" : " - item group " + categoryData[cat].item_groups[0])}
		onClick={() => {
			if(active){
				dispatch(changeSlug('/'));
				updateHistory('/');
			}else{
				dispatch(changeSlug(cat));
				updateHistory('/product-category/'+cat);
			}
		}}
		className={`cat-item cat-colour-${parent}${cat === parent? ' parent' : ''}${active? ' active' : ''}${(layout && !active) ? " hide-inactive" : ""}`}>
		{name}
	</button>
}
