import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { setEmail } from '../../redux/userSlice';

export function UserInputEmail() {
	const dispatch = useDispatch();
	const [enteredEmail, setEnteredEmail] = useState('');
	const newPassNeeded = useSelector(state => state.user.newPassNeeded);

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			dispatch(setEmail(enteredEmail.trim()));
		}, 300);
		return () => clearTimeout(delayDebounce)
	}, [enteredEmail]); //eslint-disable-line react-hooks/exhaustive-deps

	return <div className="form-group">
		<label htmlFor="formEmail">
			Email address
		</label>
		<input
			id="formEmail"
			placeholder="Enter email"
			autoFocus
			type="email"
			value={enteredEmail}
			onChange={e => {
				setEnteredEmail(e.target.value);
			}}
			disabled={newPassNeeded}
		/>
	</div>
}

export function UserInputPassword({isNew, password, setPassword}) {
	const newOrOld = (isNew ? "New" : "");
	return <div className="form-group">
		<label className="form-label" htmlFor={"form"+newOrOld+"Password"}>{newOrOld} Password</label>
		<input
			id={"form"+newOrOld+"Password"}
			type="password"
			placeholder={newOrOld+" Password"}
			value={password}
			onChange={e => setPassword(e.target.value)}
		/>
		{isNew ? <UserPasswordRequirements password={password} /> : <Fragment /> }
	</div>
}

function UserPasswordRequirements({ password }) {
	const length = password.length > 7;
	const oneLowerCase = /[a-z]/g.test(password);
	const oneNumber = /\d/g.test(password);

	if(password.length > 0 && (!length || !oneLowerCase || !oneNumber)){
		return(<ul className="password-requirements">
			{!length ? <li>must be at least 8 characters long</li> : <Fragment />}
			{!oneLowerCase ? <li>must include a lower case letter</li> : <Fragment />}
			{!oneNumber ? <li>must include a number</li> : <Fragment />}
		</ul>);
	}else{
		return <Fragment />
	}
}
