import { roundToDecimalPlaces } from './money';
import type { Promo } from './types/promo';

type PromoItem = {
    promoType: 'percent' | 'flat';
    promoValue: number;
}

export function getOrderTotalWithPromoApplied(
    orderProducts: Array<{
        product: { product_code: string; item_group: number; price: number; },
        quantity: number,
    }>,
    promo: Promo,
    discountsPercentByGroup?: Record<number, number> | null,
	customerSpecificProducts?: Record<string, Partial<{
        price: number;
        item_group: number;
        product_code: string;
    }>> | null,
): string {
    let total = 0;

    for(const item of orderProducts) {
        const customerSpecificPrice = customerSpecificProducts?.[item.product.product_code]?.price;
        const discount = discountsPercentByGroup?.[item.product.item_group];

        const price = customerSpecificPrice
            ? customerSpecificPrice
            : discount
            ? item.product.price * (1 - discount)
            : item.product.price;

        total += getPromoPriceForProduct(
            promo,
            {
                product_code: item.product.product_code,
                priceTotal: item.quantity * price,
                item_group: item.product.item_group,
            },
            item.quantity,
        ) ?? item.quantity * price;
    }

    return roundToDecimalPlaces(total, 2, 'up').toFixed(2);
}

export function getSubTotalWithPromo(
    promo: Promo,
    product: { product_code: string; price: number; item_group: number; },
    quantity: number,
    roundPlaces?: number,
) {
    const promoPrice = getPromoPriceForProduct(
        promo,
        {
            product_code: product.product_code,
            priceTotal: product.price * quantity,
            item_group: product.item_group,
        },
        quantity,
    );

    if(!promoPrice) {
        return;
    }

    if(!roundPlaces) {
        return promoPrice;
    }

   return roundToDecimalPlaces(promoPrice, 2, 'up')
}

function getPromoPriceForProduct(
    promo: Promo,
    product: { product_code: string; priceTotal: number; item_group: number; },
    quantity = 1, 
): number | undefined {
    const groupPromo = promo.itemGroups?.[product.item_group];
    const codePromo = promo.productCodes?.[product.product_code];

    if(!groupPromo && !codePromo) {
        return;
    }

    let promoPrice = product.priceTotal;

    if(groupPromo) {
        promoPrice = applyPromo(groupPromo, promoPrice, quantity) ?? promoPrice;
    }

    return codePromo
        ? applyPromo(codePromo, promoPrice, quantity) ?? promoPrice
        : promoPrice;
}

function applyPromo(promoItem: PromoItem, promoPriceTotal: number, quantity: number) {
	if(promoItem.promoType === 'percent') {
		return promoPriceTotal * (1 - promoItem.promoValue * 0.01)
	}

	if(promoItem.promoType === 'flat') {
        const priceFlat = promoPriceTotal - (promoItem.promoValue * quantity);

		return priceFlat > 0 ? priceFlat : 0;
	}

	return;
}
