export const parentSlugList = ["pvc-pipe-fittings","pe-pipe-fittings","galvanised-fittings","hose","fertigation","valves","filtration","sprinklers","drip","landscape-sports","hp-now"];

export const slugsByGroup = {
	101: "irriflex",
	110:"ndj-amnon",
	120:"ndj-top",
	130:"ndj-drip-tape",
	140:"drip-tape-fittings",
	150:"ndj-click-tif-drippers",
	160:"ndj-click-tif-sets",
	170:"ndj-drip-accessories",
	200:"ndj-micro-sprinklers-accessories-and-spares",
	210:"ndj-outdoor-sprinklers-accessories-and-spares",
	280:"tavlit-automatic-filters",
	290:"tavlit-plastic-filters",
	310:"amiad-plastic-filters",
	320:"yamit-metal-manual-filters",
	330:"yamit-metal-automatic-screen-filters",
	340:"yamit-sand-filters",
	360:"odis-filtration",
	370:"ari-plastic-valves",
	390:"bermad-plastic-valves",
	400:"vdl-pvc-manual-valves",
	410:"pvc-manual-valves",
	420:"metal-manual-valves",
	430:"low-voltage-cable",
	440:"valve-boxes",
	460:"water-meters",
	470:"pressure-gauges-and-accessories",
	500:"pvc-pressure-pipe",
	510:"rhino-pvc-pressure-fittings",
	520:"ptfe-glue-cleaner-accessories",
	550:"vdl-pvc-pressure-fittings",
	610:"ldpe-pipe",
	620:"pe-pipe-6-8-bar",
	630:"pe-pipe-10-bar-12-bar",
	640:"supreme-fittings-valves-accessories",
	650:"supreme-saddles",
	660:"electrofusion-fittings",
	670:"barbed-fittings-valves",
	680:"nut-lock-fittings",
	690:"tavlit-pp-threaded-fittings",
	710:"tsp-galvanised-fittings",
	720:"rioflex-layflat-pipe-fittings-accessories",
	730:"tavlit-swivel-fittings",
	740:"landscape-dripline",
	810:"hose-fittings",
	800: "hansen",
	880:"irritrol",
	890:"hunter",
	901:"tefen-injectors-and-spares",
	480:"hp-now"
};

export const parentSlugsByGroup = {
	101: "hose",
	110:"drip",120:"drip",130:"drip",140:"drip",150:"drip",160:"drip",170:"drip",200:"sprinklers",210:"sprinklers",280:"filtration",290:"filtration",310:"filtration",320:"filtration",330:"filtration",340:"filtration",360:"filtration",370:"valves",390:"valves",400:"valves",410:"valves",420:"valves",430:"valves",440:"valves",460:"valves",470:"valves",480:"hp-now",500:"pvc-pipe-fittings",510:"pvc-pipe-fittings",520:"pvc-pipe-fittings",550:"pvc-pipe-fittings",610:"pe-pipe-fittings",620:"pe-pipe-fittings",630:"pe-pipe-fittings",640:"pe-pipe-fittings",650:"pe-pipe-fittings",660:"pe-pipe-fittings",670:"pe-pipe-fittings",680:"pe-pipe-fittings",690:"pe-pipe-fittings",800: "pe-pipe-fittings",710:"galvanised-fittings",720:"pe-pipe-fittings",730:"landscape-sports",740:"landscape-sports",810:"hose",880:"landscape-sports",890:"landscape-sports",901:"fertigation"
};

export const categoryData = {
	"pvc-pipe-fittings": {
		id: 21,
		name: "PVC Pipe and Fittings",
		slug: "pvc-pipe-fittings",
		parent: 0,
		description: "Price List Section",
		children: ["rhino-pvc-pressure-fittings","ptfe-glue-cleaner-accessories","pvc-pressure-pipe","vdl-pvc-pressure-fittings"],
		item_groups: [
			510,
			520,
			500,
			550
		]
	},
	"rhino-pvc-pressure-fittings": {
		id: 54,
		name: "Rhino PVC Pressure Fittings",
		slug: "rhino-pvc-pressure-fittings",
		parent: 21,
		description: "Group 510",
		item_groups: [510],
	},
	"ptfe-glue-cleaner-accessories": {
		id: 55,
		name: "PTFE, Glue, Cleaner and Accessories",
		slug: "ptfe-glue-cleaner-accessories",
		parent: 21,
		description: "Group 520",
		item_groups: [520],
	},
	"pvc-pressure-pipe": {
		id: 53,
		name: "PVC Pressure Pipe",
		slug: "pvc-pressure-pipe",
		parent: 21,
		description: "Group 500",
		item_groups: [500],
	},
	"vdl-pvc-pressure-fittings": {
		id: 58,
		name: "VDL PVC Pressure Fittings",
		slug: "vdl-pvc-pressure-fittings",
		parent: 21,
		description: "Group 550",
		item_groups: [550],
	},
	"pe-pipe-fittings": {
		id: 22,
		name: "PE Pipe and Fittings",
		slug: "pe-pipe-fittings",
		parent: 0,
		description: "Price List Section",
		children: [
			"barbed-fittings-valves",
			"electrofusion-fittings",
			"ldpe-pipe",
			"nut-lock-fittings",
			"pe-pipe-10-bar-12-bar",
			"pe-pipe-6-8-bar",
			"rioflex-layflat-pipe-fittings-accessories",
			"supreme-fittings-valves-accessories",
			"supreme-saddles",
			"tavlit-pp-threaded-fittings",
			"hansen"
		],
		item_groups: [
			670,
			660,
			610,
			680,
			630,
			620,
			720,
			640,
			650,
			690,
			800,
		]
	},
	"barbed-fittings-valves": {
		id: 226,
		name: "Barbed Fittings and Valves",
		slug: "barbed-fittings-valves",
		parent: 22,
		description: "Group 670",
		item_groups: [670],
	},
	"electrofusion-fittings": {
		id: 65,
		name: "Electrofusion Fittings",
		slug: "electrofusion-fittings",
		parent: 22,
		description: "Group 660",
		item_groups: [660],
	},
	"ldpe-pipe": {
		id: 59,
		name: "LDPE Pipe",
		slug: "ldpe-pipe",
		parent: 22,
		description: "Group 610",
		item_groups: [610],
	},
	"nut-lock-fittings": {
		id: 67,
		name: "Nut Lock Fittings",
		slug: "nut-lock-fittings",
		parent: 22,
		description: "Group 680",
		item_groups: [680],
	},
	"pe-pipe-10-bar-12-bar": {
		id: 218,
		name: "PE Pipe 10 and 12 bar",
		slug: "pe-pipe-10-bar-12-bar",
		parent: 22,
		description: "Group 630",
		item_groups: [630],
	},
	"pe-pipe-6-8-bar": {
		id: 216,
		name: "PE Pipe 6 and 8 bar",
		slug: "pe-pipe-6-8-bar",
		parent: 22,
		description: "Group 620",
		item_groups: [620],
	},
	"rioflex-layflat-pipe-fittings-accessories": {
		id: 224,
		name: "Rioflex Layflat Pipe, Fittings and Accessories",
		slug: "rioflex-layflat-pipe-fittings-accessories",
		parent: 22,
		description: "Group 720",
		item_groups: [720],
	},
	"supreme-fittings-valves-accessories": {
		id: 221,
		name: "Supreme Fittings, Valves and Accessories",
		slug: "supreme-fittings-valves-accessories",
		parent: 22,
		description: "Group 640",
		item_groups: [640],
	},
	"supreme-saddles": {
		id: 64,
		name: "Supreme Saddles",
		slug: "supreme-saddles",
		parent: 22,
		description: "Group 650",
		item_groups: [650],
	},
	"tavlit-pp-threaded-fittings": {
		id: 68,
		name: "Tavlit PP Threaded Fittings",
		slug: "tavlit-pp-threaded-fittings",
		parent: 22,
		description: "Group 690",
		item_groups: [690],
	},
	hansen: {
		id: 70,
		name: "Hansen",
		slug: "hansen",
		parent: 22,
		description: "Group 800",
		item_groups: [800],
	},
	"galvanised-fittings": {
		id: 23,
		name: "Galvanised Fittings",
		slug: "galvanised-fittings",
		parent: 0,
		description: "Price List Section",
		children: ["tsp-galvanised-fittings"],
		item_groups: [
			710
		]
	},
	"tsp-galvanised-fittings": {
		id: 69,
		name: "TSP Galvanised Fittings",
		slug: "tsp-galvanised-fittings",
		parent: 23,
		description: "Group 710",
		item_groups: [710],
	},
	hose: {
		id: 25,
		name: "Hose",
		slug: "hose",
		parent: 0,
		description: "Price List Section",
		children: ["hose-fittings", "irriflex"],
		item_groups: [
			101,
			810
		]
	},
	"hose-fittings": {
		id: 74,
		name: "Hose Fittings",
		slug: "hose-fittings",
		parent: 25,
		description: "Group 810",
		item_groups: [810],
	},
	irriflex: {
		id: 83,
		name: "Irriflex",
		slug: "irriflex",
		parent: 25,
		description: "Group 101",
		item_groups: [101],
	},
	fertigation: {
		id: 20,
		name: "Fertigation",
		slug: "fertigation",
		parent: 0,
		description: "Price List Section",
		children: ["tefen-injectors-and-spares"],
		item_groups: [
			901
		]
	},
	"tefen-injectors-and-spares": {
		id: 77,
		name: "Tefen Injectors and Spares",
		slug: "tefen-injectors-and-spares",
		parent: 20,
		description: "Group 901",
		item_groups: [901],
	},
	valves: {
		id: 19,
		name: "Valves",
		slug: "valves",
		parent: 0,
		description: "Price List Section",
		children: [
			"ari-plastic-valves",
			"bermad-plastic-valves",
			"low-voltage-cable",
			"metal-manual-valves",
			"pvc-manual-valves",
			"pressure-gauges-and-accessories",
			"valve-boxes",
			"vdl-pvc-manual-valves",
			"water-meters"
		],
		item_groups: [
			370,
			390,
			430,
			420,
			410,
			470,
			440,
			400,
			460
		]
	},
	"ari-plastic-valves": {
		id: 43,
		name: "ARI Plastic Valves",
		slug: "ari-plastic-valves",
		parent: 19,
		description: "Group 370",
		item_groups: [370],
	},
	"bermad-plastic-valves": {
		id: 44,
		name: "Bermad Plastic Valves",
		slug: "bermad-plastic-valves",
		parent: 19,
		description: "Group 390",
		item_groups: [390],
	},
	"low-voltage-cable": {
		id: 48,
		name: "Low Voltage Cable",
		slug: "low-voltage-cable",
		parent: 19,
		description: "Group 430",
		item_groups: [430],
	},
	"metal-manual-valves": {
		id: 47,
		name: "Metal Manual Valves",
		slug: "metal-manual-valves",
		parent: 19,
		description: "Group 420",
		item_groups: [420],
	},
	"pvc-manual-valves": {
		id: 46,
		name: "PVC Manual Valves",
		slug: "pvc-manual-valves",
		parent: 19,
		description: "Group 410",
		item_groups: [410],
	},
	"pressure-gauges-and-accessories": {
		id: 51,
		name: "Pressure Gauges and Accessories",
		slug: "pressure-gauges-and-accessories",
		parent: 19,
		description: "Group 470",
		item_groups: [470],
	},
	"valve-boxes": {
		id: 49,
		name: "Valve Boxes",
		slug: "valve-boxes",
		parent: 19,
		description: "Group 440",
		item_groups: [440],
	},
	"vdl-pvc-manual-valves": {
		id: 45,
		name: "VDL PVC Manual Valves",
		slug: "vdl-pvc-manual-valves",
		parent: 19,
		description: "Group 400",
		item_groups: [400],
	},
	"water-meters": {
		id: 50,
		name: "Water Meters",
		slug: "water-meters",
		parent: 19,
		description: "Group 460",
		item_groups: [460],
	},
	filtration: {
		id: 18,
		name: "Filtration",
		slug: "filtration",
		parent: 0,
		description: "Price List Section",
		children: [
			"amiad-plastic-filters",
			"odis-filtration",
			"tavlit-automatic-filters",
			"tavlit-plastic-filters",
			"yamit-metal-automatic-screen-filters",
			"yamit-metal-manual-filters",
			"yamit-sand-filters"
		],
		item_groups: [
			310,
			360,
			280,
			290,
			330,
			320,
			340
		]
	},
	"amiad-plastic-filters": {
		id: 38,
		name: "Amiad Plastic Filters",
		slug: "amiad-plastic-filters",
		parent: 18,
		description: "Group 310",
		item_groups: [310],
	},
	"odis-filtration": {
		id: 42,
		name: "Odis Filtration",
		slug: "odis-filtration",
		parent: 18,
		description: "Group 360",
		item_groups: [360],
	},
	"tavlit-automatic-filters": {
		id: 36,
		name: "Tavlit Automatic Filters",
		slug: "tavlit-automatic-filters",
		parent: 18,
		description: "Group 280",
		item_groups: [280],
	},
	"tavlit-plastic-filters": {
		id: 37,
		name: "Tavlit Plastic Filters",
		slug: "tavlit-plastic-filters",
		parent: 18,
		description: "Group 290",
		item_groups: [290],
	},
	"yamit-metal-automatic-screen-filters": {
		id: 40,
		name: "Yamit Metal Automatic Screen Filters",
		slug: "yamit-metal-automatic-screen-filters",
		parent: 18,
		description: "Group 330",
		item_groups: [330],
	},
	"yamit-metal-manual-filters": {
		id: 39,
		name: "Yamit Metal Manual Filters",
		slug: "yamit-metal-manual-filters",
		parent: 18,
		description: "Group 320",
		item_groups: [320],
	},
	"yamit-sand-filters": {
		id: 41,
		name: "Yamit Sand Filters",
		slug: "yamit-sand-filters",
		parent: 18,
		description: "Group 340",
		item_groups: [340],
	},
	sprinklers: {
		id: 17,
		name: "Sprinklers",
		slug: "sprinklers",
		parent: 0,
		description: "Price List Section",
		children: ["ndj-micro-sprinklers-accessories-and-spares","ndj-outdoor-sprinklers-accessories-and-spares"],
		item_groups: [
			200,
			210
		]
	},
	"ndj-micro-sprinklers-accessories-and-spares": {
		id: 34,
		name: "NDJ Micro Sprinklers, Accessories and Spares",
		slug: "ndj-micro-sprinklers-accessories-and-spares",
		parent: 17,
		description: "Group 200",
		item_groups: [200],
	},
	"micro-sprinkler-stands": {
		id: 57,
		name: "Micro Sprinkler Stands",
		slug: "micro-sprinkler-stands",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-bases": {
		id: 56,
		name: "NDJ Bases",
		slug: "ndj-bases",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-dan-greenspin-bridgeless-swivel": {
		id: 63,
		name: "NDJ DAN Greenspin Bridgeless Swivel",
		slug: "ndj-dan-greenspin-bridgeless-swivel",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-dan-modular-push-fit": {
		id: 71,
		name: "NDJ DAN Modular Push Fit",
		slug: "ndj-dan-modular-push-fit",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-droppers": {
		id: 78,
		name: "NDJ Droppers",
		slug: "ndj-droppers",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-foggers": {
		id: 79,
		name: "NDJ Foggers",
		slug: "ndj-foggers",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-lpd": {
		id: 80,
		name: "NDJ LPD",
		slug: "ndj-lpd",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-naan-hadar-sprinklers": {
		id: 81,
		name: "NDJ NAAN HADAR Sprinklers",
		slug: "ndj-naan-hadar-sprinklers",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"plugs-blind-sprinklers": {
		id: 82,
		name: "Plugs/Blind Sprinklers",
		slug: "plugs-blind-sprinklers",
		parent: 34,
		description: "NDJ Micro Sprinklers, Accessories and Spares",
	},
	"ndj-outdoor-sprinklers-accessories-and-spares": {
		id: 35,
		name: "NDJ Outdoor Sprinklers, Accessories and Spares",
		slug: "ndj-outdoor-sprinklers-accessories-and-spares",
		parent: 17,
		description: "Group 210",
		item_groups: [210],
	},
	drip: {
		id: 16,
		name: "Drip",
		slug: "drip",
		parent: 0,
		description: "Price List Section",
		children: [
			"drip-tape-fittings",
			"ndj-amnon",
			"ndj-click-tif-drippers",
			"ndj-click-tif-sets",
			"ndj-drip-accessories",
			"ndj-drip-tape",
			"ndj-top"
		],
		item_groups: [
			140,
			110,
			150,
			160,
			170,
			130,
			120
		]
	},
	"drip-tape-fittings": {
		id: 30,
		name: "Drip Tape Fittings",
		slug: "drip-tape-fittings",
		parent: 16,
		description: "Group 140",
		item_groups: [140],
	},
	"ndj-amnon": {
		id: 27,
		name: "NDJ Amnon",
		slug: "ndj-amnon",
		parent: 16,
		description: "Group 110",
		item_groups: [110],
	},
	"ndj-click-tif-drippers": {
		id: 31,
		name: "NDJ Click Tif Drippers",
		slug: "ndj-click-tif-drippers",
		parent: 16,
		description: "Group 150",
		item_groups: [150],
	},
	"ndj-click-tif-sets": {
		id: 32,
		name: "NDJ Click Tif Sets",
		slug: "ndj-click-tif-sets",
		parent: 16,
		description: "Group 160",
		item_groups: [160],
	},
	"ndj-drip-accessories": {
		id: 33,
		name: "NDJ Drip Accessories",
		slug: "ndj-drip-accessories",
		parent: 16,
		description: "Group 170",
		item_groups: [170],
	},
	"ndj-drip-tape": {
		id: 29,
		name: "NDJ Drip Tape",
		slug: "ndj-drip-tape",
		parent: 16,
		description: "Group 130",
		item_groups: [130],
	},
	"ndj-top": {
		id: 28,
		name: "NDJ Top",
		slug: "ndj-top",
		parent: 16,
		description: "Group 120",
		item_groups: [120],
	},
	"landscape-sports": {
		id: 24,
		name: "Landscape and Sports",
		slug: "landscape-sports",
		parent: 0,
		description: "Price List Section",
		children: [
			"hunter",
			"irritrol",
			"landscape-dripline",
			"tavlit-swivel-fittings"
		],
		item_groups: [
			890,
			880,
			740,
			730
		]
	},
	hunter: {
		id: 76,
		name: "Hunter",
		slug: "hunter",
		parent: 24,
		description: "Group 890",
		item_groups: [890],
	},
	irritrol: {
		id: 75,
		name: "Irritrol",
		slug: "irritrol",
		parent: 24,
		description: "Group 880",
		item_groups: [880],
	},
	"landscape-dripline": {
		id: 73,
		name: "Landscape Dripline",
		slug: "landscape-dripline",
		parent: 24,
		description: "Group 740",
		item_groups: [740],
	},
	"tavlit-swivel-fittings": {
		id: 72,
		name: "Tavlit Swivel Fittings",
		slug: "tavlit-swivel-fittings",
		parent: 24,
		description: "Group 730",
		item_groups: [730],
	},
	"hp-now": {
		id: 26,
		name: "HP Now",
		slug: "hp-now",
		parent: 0,
		description: "Group 480",
		item_groups: [480],
		children: [],
	}
};
