import { useEffect } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { changeSlug, clearSlug } from '../redux/productsSlice';
import { slugsByGroup, categoryData } from '../business/categoryData';
import { parseScrewThreadFromSlug } from '../utils';
import { useAppDispatch } from './customHooks';

const GROUPS = Object.keys(slugsByGroup);
const SLUGS = Object.keys(categoryData);

export function useRouterPriceListSearch(setSearchInput: (search: string) => void) {
	const dispatch = useAppDispatch();
	const match = useMatch("/:parent/:child");

	useEffect(() => {
		if(match?.params.parent && match.params.child) {
			const { parent, child } = match.params;

			switch(parent){
				case "code": {
					dispatch(clearSlug());
					setSearchInput(child);
					break;
				}
				case "slug": {
					setSearchInput(child);
					break;
				}
				case "product": {
					setSearchInput(child.replaceAll('-',' '));
					break;
				}
				case "pipe-diameter": {
					if(/\d{1,3}mm/.test(child)){
						setSearchInput(child);
					}
					break;
				}
				case "valve-size":
					dispatch(changeSlug("valves"));
					setSearchInput(parseScrewThreadFromSlug(child));
					break;
				case "screw-thread":
					setSearchInput(parseScrewThreadFromSlug(child));
					break;
			}
		}
	}, [match, setSearchInput]); //eslint-disable-line react-hooks/exhaustive-deps
}

export function useRouterCategories() {
	const dispatch = useAppDispatch();
	const matchCategory = useMatch("/product-category/:catparent/:catslug");
	const matchCatGroup = useMatch("/product-category/groups/:itemgroup");
	const matchGroup = useMatch("/group/:itemgroup");
	const matchSlugOrGroup = useMatch("/product-category/:slugorgroup");

	useEffect(() => {
		const slugorgroup = matchGroup?.params.itemgroup
			|| matchCatGroup?.params.itemgroup
			|| matchCategory?.params.catslug
			|| matchSlugOrGroup?.params.slugorgroup;

		if(slugorgroup && GROUPS.includes(slugorgroup)){
			const group = Number(slugorgroup);
			dispatch(changeSlug(slugsByGroup[group]));
		}else if(slugorgroup && SLUGS.includes(slugorgroup)) {
			changeSlug(slugorgroup)
		}
	}, [matchCategory, matchCatGroup, matchGroup, matchSlugOrGroup]) //eslint-disable-line react-hooks/exhaustive-deps
}

export function useRouterCategoryHistory() {
	const navigate = useNavigate()

	return (path: string) => {
		navigate(path, { replace: true })
	}
}
