import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	initialized: false,
	updated: false,
	registration: null,
	offline: false
};

export function pwaPreloadedState() {
	try {
		const preloadedState = {
			...initialState,
			standalone: window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
		}
		return preloadedState;
	}catch(e){
		console.warn(e);
		return initialState;
	}
}

// https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

export const pwaSlice = createSlice({
	name: 'pwa',
	initialState: initialState,
	reducers: {
		init: state => {
			state.initialized = true;
		},
		updated: state => {
			state.updated = true;
		},
		registration: (state, action) => {
			state.registration = action.payload;
		},
		toggleOffline: (state, action) => {
			state.offline = action.payload;
		},
		toggleStandalone: (state,action) => {
			state.standalone = action.payload;
		}
	}
});

export default pwaSlice.reducer;
export const { init, updated, registration, toggleOffline, toggleStandalone } = pwaSlice.actions;
