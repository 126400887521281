import { useAppDispatch, useAppSelector } from 'redux/customHooks';
import { fetchPromo, removePromo } from 'redux/basketSlice';
import { useState } from 'react';
import { Promo } from 'pennyuk-business/src';

export function OrderPromoCode() {
    const [promoCode, setPromoCode] = useState('');
    const dispatch = useAppDispatch();
    const promoStatus = useAppSelector(state => state.basket.promoStatus);
    const promo = useAppSelector(state => state.basket.promo)

    return (
        <div>
            <label htmlFor="formPromo"><i>Enter any promotional codes here</i></label>
            <br />
            <input
                id="formPromo"
                className="field"
                name="promo-code"
                placeholder="Enter Promo Code"
                autoFocus
                type="text"
                value={promoCode}
                onChange={e => setPromoCode(e.target.value)}
            />

            <button
                type="button"
                name={`${promo ? 'remove' : 'apply'} promo`}
                disabled={promoStatus === 'loading'}
                className="promo-button lightblue-rounded-button"
                onClick={() => {
                    if(promo) {
                        dispatch(removePromo());
                    }else{
                        dispatch(fetchPromo(promoCode));
                    }
                }}
            >
                {promoStatus === 'loading'
                    ? <i>Checking</i>
                    : promo
                    ? <span>Remove</span>
                    : <span>Apply</span>
                }
            </button>
            {promo && <PromoApplyMessage promo={promo} />}
        </div>
    );
}

function PromoApplyMessage({ promo }: { promo: Promo }) {
    if(Date.now() < promo.start) {
        return (
            <span className='promo-status'>
                <b>{promo.code.toUpperCase()}</b> <i><b>starts</b> {new Date(promo.start).toDateString()}</i>
            </span>
        );
    }

    if(Date.now() > promo.end) {
        return (
            <span className='promo-status'>
                <b>{promo.code.toUpperCase()}</b> <i><b>ended</b> {new Date(promo.end).toDateString()}</i>
            </span>
        );
    }

    return (
        <span className='promo-status'>
            <b>{promo.code.toUpperCase()}</b> applied
        </span>
    );
}
